import { CardSubTypes } from '@/common/models/CardSubTypes';
import { CardTypes } from '@/common/models/CardTypes';
import { Guid } from '@/common/models/Guid';
import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';
import { Theme } from '@/theme';

import { EmbedProperties } from './EmbedProperties';

export class Embed implements SiteCard {
  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.Embed;
  name: string;
  embedUrl: string;
  properties: EmbedProperties;

  constructor(props?: Partial<Embed>) {
    props = props || {};
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);
    this.properties = new EmbedProperties(props.properties);
  }

  getButtonTextColor(properties: SitePageProperties, theme: Theme) {
    return this.properties.getButtonTextColor(properties, theme);
  }

  getButtonBackgroundTextColor(properties: SitePageProperties, theme: Theme) {
    return this.properties.getButtonBackgroundTextColor(properties, theme);
  }

  isValidEmbed(): boolean {
    return (
      !!this.embedUrl ||
      !!this.properties.html ||
      !!this.properties.getAmondoProperties().imprintId
    );
  }

  isEmbeddedIFrame(subType: CardSubTypes) {
    return (
      subType &&
      (subType === CardSubTypes.Iframe ||
        subType === CardSubTypes.Matterport ||
        subType === CardSubTypes.Issuu ||
        subType === CardSubTypes.GoogleMaps ||
        subType === CardSubTypes.LinkedIn)
    );
  }

  getIframeProps() {
    return this.properties.UseAspectRatio && this.properties.AspectRatio
      ? { style: { aspectRatio: this.properties.AspectRatio } }
      : { height: this.properties.height ?? '380px' };
  }

  isDynamicEmbeddedIFrame(subType: CardSubTypes) {
    return (
      subType &&
      (subType === CardSubTypes.TikTok ||
        subType === CardSubTypes.Twitter ||
        subType === CardSubTypes.Instagram ||
        subType === CardSubTypes.Facebook)
    );
  }

  resolveBorderRadius(subType: CardSubTypes) {
    switch (subType) {
      case CardSubTypes.LinkedIn:
      case CardSubTypes.Twitter:
      case CardSubTypes.AppleMusic:
        return '5px';
      default:
        return undefined;
    }
  }

  getInputLabel(subType: CardSubTypes) {
    switch (subType) {
      case CardSubTypes.Html:
        return 'Html code';
      default:
        return 'Embed code';
    }
  }

  getInputPlaceholder(subType: CardSubTypes) {
    switch (subType) {
      case CardSubTypes.Matterport:
        return 'https://my.matterport.com/show/?m=xxxxxxxxxxx';
      default:
        return undefined;
    }
  }

  getInputHelpPrefix(subType: CardSubTypes) {
    switch (subType) {
      case CardSubTypes.Html:
        return 'Anything that you put in here we host in iFrame. The code will be placed into the “body” tag of a HTML embed.';
      default:
        return 'Need help with the embed code?';
    }
  }
}
