import { immerable } from 'immer';

import { classFromJsonOrFallback } from '@/common/utils/TypeFunctions';
import { theme } from '@/theme';

export class TreasureHuntDrawerProperties {
  [immerable] = true;

  headerTextColor: string = theme.black;
  headerBackgroundColor: string = theme.other.colors.white;
  bodyTextColor: string = theme.black;
  bodyBackgroundColor: string = theme.other.colors.white;

  constructor(props?: Partial<TreasureHuntDrawerProperties>) {
    props = props || {};
    Object.assign(this, props);
  }

  public static fromJsonOrNew(
    value: string,
    fallback?: Partial<TreasureHuntDrawerProperties>
  ) {
    return classFromJsonOrFallback(
      TreasureHuntDrawerProperties,
      value,
      new TreasureHuntDrawerProperties(fallback)
    );
  }
}
