import { immerable } from 'immer';

import { SiteCardProperties } from '@/common/models/SiteCardProperties';
import { asBoolean } from '@/common/utils/BooleanFunctions';
import { asNumber } from '@/common/utils/NumberFunctions';
import { theme } from '@/theme';

import { ImageDataModel } from '../../ImageDataModel';

export class LiveSurveyProperties implements SiteCardProperties {
  [immerable] = true;

  ButtonTextColor?: string;
  ButtonBackgroundColor?: string;

  //Header
  HeaderLogoDisabled: boolean = false;
  HeaderLogoJson?: string;
  get HeaderLogo(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.HeaderLogoJson);
  }
  HeaderCoverImageJson?: string;
  get HeaderCoverImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.HeaderCoverImageJson);
  }
  HeaderBgColor: string;
  HeaderColor: string;
  HeaderTitle?: string;
  HeaderSubtitle?: string;

  HeaderTabActiveColor?: string;
  HeaderTabInactiveColor?: string;
  HeaderQuestionsCountColor?: string;

  //Body
  BodyBgColor: string;
  BodyBgImageJson?: string;
  get BodyBgImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.BodyBgImageJson);
  }

  BodyQuestionBgColor: string;
  BodyQuestionHeadingColor: string;
  BodyQuestionTextColor: string;

  //Footer
  FooterBgColor?: string;
  FooterColor?: string;

  //Questions
  QuestionInputPlaceholder: string;
  ReactionFillColor: string;
  MaxParticipantQuestions: number;
  QuestionMaxCharacterLength: number;
  QuestionSubmissionsEnabled: boolean;

  static defaults: Partial<LiveSurveyProperties> = {
    //Questions
    MaxParticipantQuestions: 3,
    QuestionMaxCharacterLength: 180,
    QuestionInputPlaceholder: 'Ask a question...',

    //Header
    HeaderLogoDisabled: false,
    HeaderBgColor: theme.other.colors.white,
    HeaderColor: theme.colors.gray[9],

    //Body
    BodyBgColor: theme.colors.gray[2],
    BodyQuestionBgColor: theme.other.colors.white,
    BodyQuestionHeadingColor: theme.colors.gray[7],
    BodyQuestionTextColor: theme.colors.gray[9],
    ReactionFillColor: theme.colors.blue[6]
  };

  constructor(props?: Partial<LiveSurveyProperties>) {
    Object.assign(this, {
      ...LiveSurveyProperties.defaults,
      ...(props || {})
    });
    this.HeaderLogoDisabled = asBoolean(this.HeaderLogoDisabled);
    this.MaxParticipantQuestions = asNumber(this.MaxParticipantQuestions);
    this.QuestionMaxCharacterLength = asNumber(this.QuestionMaxCharacterLength);
    this.QuestionSubmissionsEnabled = asBoolean(
      this.QuestionSubmissionsEnabled
    );
  }
}
