import { LegacyTriviaLeaderboard } from '@/common/models/legacy-trivia-leaderboard';
import { SiteCardProperties } from '@/common/models/SiteCardProperties';
import { asBoolean } from '@/common/utils/BooleanFunctions';
import { asNumber } from '@/common/utils/NumberFunctions';

import { ColorRGBA, colorRGBAOrDefault } from '../ColorRGBA';
import { ImageDataModel } from '../ImageDataModel';

export class LegacyTriviaLeaderboardProperties implements SiteCardProperties {
  ButtonTextColor: string = '';
  ButtonBackgroundColor: string = '';
  HeadingColor: ColorRGBA;
  BackgroundImageUrl: string = '';

  get BackgroundImage() {
    return ImageDataModel.fromJsonOrUrl(this.BackgroundImageUrl);
  }

  LogoImageUrl: string = '';

  get LogoImage() {
    return ImageDataModel.fromJsonOrUrl(this.LogoImageUrl);
  }

  BackgroundColor: ColorRGBA;
  RankItemsColor: ColorRGBA;
  RankItemsBackground: ColorRGBA;
  LogoEnabled: boolean;
  BackgroundImageEnabled: boolean;
  ShowFullName: boolean;
  AllowExpandEntries: boolean;
  NumberOfTopEntries: number;

  constructor(props?: Partial<LegacyTriviaLeaderboardProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.LogoEnabled = asBoolean(props.LogoEnabled, true);
    this.BackgroundImageEnabled = asBoolean(
      props.BackgroundImageEnabled,
      false
    );
    this.ShowFullName = asBoolean(props.ShowFullName, true);
    this.AllowExpandEntries = asBoolean(props.AllowExpandEntries, true);
    this.NumberOfTopEntries = asNumber(
      props.NumberOfTopEntries,
      LegacyTriviaLeaderboard.DefaultEntries
    );
    this.BackgroundColor = colorRGBAOrDefault(
      props.BackgroundColor,
      ColorRGBA.fromHex('#ffffff')
    );
    this.RankItemsColor = colorRGBAOrDefault(
      props.RankItemsColor,
      ColorRGBA.fromHex('#ffffff')
    );
    this.RankItemsBackground = colorRGBAOrDefault(
      props.RankItemsBackground,
      ColorRGBA.fromHex('#000000')
    );
    this.HeadingColor = colorRGBAOrDefault(
      props.HeadingColor,
      ColorRGBA.fromHex('#000000')
    );
  }

  getBackgroundColor() {
    return this.BackgroundColor;
  }

  getRankItemsColor() {
    return this.RankItemsColor;
  }

  getRankItemsBackgroundColor() {
    return this.RankItemsBackground;
  }

  getHeadingColor() {
    return this.HeadingColor;
  }
}
