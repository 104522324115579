import { immerable } from 'immer';

import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { asBoolean } from '@/common/utils/BooleanFunctions';

import { CardLayout } from './CardLayout';
import { ImageDataModel } from './ImageDataModel';
import { Site } from './site';

export class CardDescriptorProperties {
  [immerable] = true;
  static DefaultShareText = 'Share link';
  static DefaultResultDownloadText = 'Share image';
  static DefaultPlayAgainText = 'Play again';
  static DefaultCompetitionButtonText = 'Enter competition';
  static BackToHubText = 'Back to hub';

  AvatarEnabled: boolean;
  AvatarImageUrl?: string;

  get AvatarImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.AvatarImageUrl);
  }

  AvatarText?: string;
  AvatarSubText?: string;

  CoverEnabled: boolean;
  CoverImageUrl?: string;

  get CoverImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.CoverImageUrl);
  }

  CoverTitle?: string;
  CoverTitleEnabled: boolean;
  CoverButtonEnabled: boolean;
  CoverButtonText?: string;
  CoverButtonColor?: string;
  CoverButtonBackgroundColor?: string;
  CoverLiveDataEnabled: boolean;

  ShellBg?: string;
  ShellC?: string;
  ShellBd?: string;

  DescriptionEnabled: boolean;
  /**
   * HTML of the description
   */
  Description?: string;

  /**
   * Lexical's state object
   */
  DescriptionStateJson?: string;

  Layout?: CardLayout;

  SharingBarColor: string;
  SharingBarTextColor: string;
  SharingBarBorderColor: string;

  MetaRobots?: string;
  MetaDescription?: string;
  MetaTitle?: string;
  MetaImage?: string;

  get MetaImageModel(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.MetaImage);
  }

  SharingEnabled: boolean;

  ResultSharingEnabled: boolean;
  ResultShareButtonText?: string;
  ResultShareButtonColor?: string;
  ResultShareButtonBackgroundColor?: string;
  ResultShareButtonBorderColor?: string;

  ResultDownloadEnabled: boolean;
  ResultDownloadButtonText?: string;
  ResultDownloadButtonColor?: string;
  ResultDownloadButtonBackgroundColor?: string;

  PlayAgainEnabled: boolean;
  PlayAgainButtonText?: string;
  PlayAgainButtonColor?: string;
  PlayAgainButtonBorderColor?: string;
  PlayAgainButtonBackgroundColor?: string;

  BackToHubButtonBackgroundColor: string;
  BackToHubButtonColor: string;
  BackToHubButtonBorderColor: string;
  BackToHubButtonText: string;

  CompetitionButtonText?: string;
  CompetitionButtonColor?: string;
  CompetitionButtonBorderColor?: string;
  CompetitionButtonBackgroundColor?: string;

  EmbedJsonData?: string;

  TemplateDataJson?: string;

  ShowCompetitionAtStart?: boolean;

  SubmittedCoverImageJson?: string;
  get SubmittedCoverImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.SubmittedCoverImageJson);
  }

  ClosedCoverImageJson?: string;
  get ClosedCoverImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.ClosedCoverImageJson);
  }

  get hasGameplayButtons() {
    return (
      this.ResultSharingEnabled ||
      this.ResultDownloadEnabled ||
      this.PlayAgainEnabled
    );
  }

  get canRenderAvatar() {
    return this.AvatarEnabled && !!this.AvatarImage && !!this.AvatarText;
  }

  get canRenderDescription() {
    return this.DescriptionEnabled && !!this.Description;
  }

  constructor(props: Partial<CardDescriptorProperties> = {}) {
    Object.assign(this, props);
    this.AvatarEnabled = asBoolean(props.AvatarEnabled);

    this.CoverEnabled = asBoolean(props.CoverEnabled);
    this.CoverTitleEnabled = asBoolean(props.CoverTitleEnabled, true);
    this.CoverButtonEnabled = asBoolean(props.CoverButtonEnabled);
    this.CoverLiveDataEnabled = asBoolean(props.CoverLiveDataEnabled, true);

    this.DescriptionEnabled = asBoolean(props.DescriptionEnabled);
    this.SharingEnabled = asBoolean(props.SharingEnabled);
    this.ResultSharingEnabled = asBoolean(props.ResultSharingEnabled, true);
    this.ResultDownloadEnabled = asBoolean(props.ResultDownloadEnabled, true);
    this.PlayAgainEnabled = asBoolean(props.PlayAgainEnabled, true);
    this.ShowCompetitionAtStart = asBoolean(props.ShowCompetitionAtStart);
  }

  getEmbedJsonData() {
    if (!this.EmbedJsonData) return new EmbedDataModel();
    try {
      return new EmbedDataModel(JSON.parse(this.EmbedJsonData));
    } catch {
      return new EmbedDataModel();
    }
  }

  updateEmbedJsonData(model: Partial<EmbedDataModel>) {
    const updated = { ...this.getEmbedJsonData(), ...model };
    this.EmbedJsonData = JSON.stringify(updated);
  }

  getEnterCompetitionButton(
    site: Site,
    properties: SitePageProperties,
    options?: GetCardDescriptorButtonOptions
  ) {
    const bgColor =
      this.CompetitionButtonBackgroundColor || options?.fallbackBackgroundColor;

    return site.resolveButton({
      properties,
      color: this.CompetitionButtonColor || options?.fallbackColor,
      backgroundColor: bgColor,
      borderColor: this.CompetitionButtonBorderColor || bgColor,
      text:
        this.CompetitionButtonText ||
        options?.fallbackText ||
        CardDescriptorProperties.DefaultCompetitionButtonText
    });
  }

  getResultDownloadButton(
    site: Site,
    properties: SitePageProperties,
    options?: GetCardDescriptorButtonOptions
  ) {
    return site.resolveButton({
      properties,
      color: this.ResultDownloadButtonColor || options?.fallbackColor,
      backgroundColor:
        this.ResultDownloadButtonBackgroundColor ||
        options?.fallbackBackgroundColor,
      text:
        this.ResultDownloadButtonText ||
        options?.fallbackText ||
        CardDescriptorProperties.DefaultResultDownloadText
    });
  }

  getResultShareButton(
    site: Site,
    properties: SitePageProperties,
    options?: GetCardDescriptorButtonOptions
  ) {
    return site.resolveButton({
      properties,
      color: this.ResultShareButtonColor || options?.fallbackColor,
      borderColor: this.ResultShareButtonBorderColor,
      backgroundColor:
        this.ResultShareButtonBackgroundColor ||
        options?.fallbackBackgroundColor,
      text:
        this.ResultShareButtonText ||
        options?.fallbackText ||
        CardDescriptorProperties.DefaultShareText
    });
  }

  getPlayAgainButton(
    site: Site,
    properties: SitePageProperties,
    options?: GetCardDescriptorButtonOptions
  ) {
    return site.resolveButton({
      properties,
      color: this.PlayAgainButtonColor || options?.fallbackColor,
      backgroundColor:
        this.PlayAgainButtonBackgroundColor || options?.fallbackBackgroundColor,
      borderColor: this.PlayAgainButtonBorderColor,
      text:
        this.PlayAgainButtonText ||
        options?.fallbackText ||
        CardDescriptorProperties.DefaultPlayAgainText
    });
  }

  getBackToHubButton(
    site: Site,
    properties: SitePageProperties,
    options?: GetCardDescriptorButtonOptions
  ) {
    return site.resolveButton({
      properties,
      color: this.BackToHubButtonColor || options?.fallbackColor,
      backgroundColor:
        this.BackToHubButtonBackgroundColor || options?.fallbackBackgroundColor,
      borderColor: this.BackToHubButtonBorderColor,
      text:
        this.BackToHubButtonText ||
        options?.fallbackText ||
        CardDescriptorProperties.BackToHubText
    });
  }
}

export interface GetCardDescriptorButtonOptions {
  fallbackBackgroundColor?: string;
  fallbackColor?: string;
  fallbackText?: string;
}

export type EmbedStyles = 'Button' | 'ImageButton' | 'Image';

export class EmbedDataModel {
  embedButtonColor?: string;
  embedButtonText?: string;
  embedButtonBackgroundColor?: string;
  embedImageUrl?: string;

  get EmbedImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.embedImageUrl);
  }
  embedStyle?: EmbedStyles = 'ImageButton';
  embedAutoStart?: boolean = true;

  constructor(props?: Partial<EmbedDataModel>) {
    if (!props) props = {};
    Object.assign(this, props);
    this.embedAutoStart = asBoolean(props.embedAutoStart, true);
  }
}
