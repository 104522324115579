import isNil from 'lodash/isNil';

import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { mapArray } from '@/common/utils/ArrayFunctions';
import { asBoolean } from '@/common/utils/BooleanFunctions';
import {
  asFloat,
  asNumber,
  asNumberOrUndefined
} from '@/common/utils/NumberFunctions';

import { ButtonDataModel } from '../ButtonDataModel';
import { ImageDataModel } from '../ImageDataModel';
import { InstantWinProbabilityDenominatorsType } from '../instant-win/shared/types';
import { Site } from '../site';

export class CardResultProperties {
  DataCaptureEnabled?: boolean;
  PlayAgainDisabled?: boolean;
  QuestionCorrectFilterJson: string;
  ProbabilityModelJson: string;
  LegacyContentJson?: string;
  ConfettiEnabled?: boolean;
  constructor(props?: Partial<CardResultProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.ConfettiEnabled = asBoolean(props.ConfettiEnabled);
    this.DataCaptureEnabled = !props.DataCaptureEnabled
      ? undefined
      : asBoolean(props.DataCaptureEnabled);
    this.PlayAgainDisabled = !props.PlayAgainDisabled
      ? undefined
      : asBoolean(props.PlayAgainDisabled);
  }

  getQuestionCorrectFilter() {
    try {
      if (!this.QuestionCorrectFilterJson) return new QuestionCorrectFilter();
      return new QuestionCorrectFilter(
        JSON.parse(this.QuestionCorrectFilterJson)
      );
    } catch (error) {
      console.error(error);
      return new QuestionCorrectFilter();
    }
  }

  setQuestionCorrectFilter(partial: Partial<QuestionCorrectFilter>) {
    const model = { ...this.getQuestionCorrectFilter(), ...partial };
    this.QuestionCorrectFilterJson = JSON.stringify(model);
  }

  getLegacyContent() {
    try {
      if (!this.LegacyContentJson) return new CardResultLegacyContent();
      return new CardResultLegacyContent(JSON.parse(this.LegacyContentJson));
    } catch (error) {
      console.error(error);
      return new CardResultLegacyContent();
    }
  }

  setLegacyContent(partial: Partial<CardResultLegacyContent>) {
    const model = { ...this.getLegacyContent(), ...partial };
    this.LegacyContentJson = JSON.stringify(model);
  }

  getProbability() {
    try {
      if (!this.ProbabilityModelJson) return new CardResultProbabilityModel();
      return new CardResultProbabilityModel(
        JSON.parse(this.ProbabilityModelJson)
      );
    } catch (error) {
      console.error(error);
      return new CardResultProbabilityModel();
    }
  }

  getImage() {
    return new ImageDataModel(this.getLegacyContent().Image);
  }

  setProbability(partial: Partial<CardResultProbabilityModel>) {
    const model = { ...this.getProbability(), ...partial };
    this.ProbabilityModelJson = JSON.stringify(model);
  }

  getTriggerRangeLabel(placeholder = 'Set range') {
    const questionCorrectFilter = this?.getQuestionCorrectFilter();
    if (
      isNil(questionCorrectFilter.MinCorrectAnswers) ||
      isNil(questionCorrectFilter.MaxCorrectAnswers)
    ) {
      return placeholder;
    }

    if (
      questionCorrectFilter.MinCorrectAnswers ===
      questionCorrectFilter.MaxCorrectAnswers
    ) {
      return `${questionCorrectFilter.MinCorrectAnswers.toLocaleString()} correct`;
    }

    return `${questionCorrectFilter.MinCorrectAnswers.toLocaleString()} - ${questionCorrectFilter.MaxCorrectAnswers.toLocaleString()} correct`;
  }
}

/**
 * To be replaced with block builder at some point
 */
export class CardResultLegacyContent {
  Title?: string;
  Description?: string;
  DescriptionLexicalStateJson?: string;
  Image?: ImageDataModel;
  DataCaptureDisabled?: boolean;
  ViewAnswersDisabled?: boolean;
  Buttons?: ButtonDataModel[];
  ImageEnabled?: boolean;
  constructor(props: Partial<CardResultLegacyContent> = {}) {
    Object.assign(this, props);
    this.Image = ImageDataModel.fromJsonOrUrl(props.Image);
    this.ViewAnswersDisabled = asBoolean(props.ViewAnswersDisabled);
    this.DataCaptureDisabled = asBoolean(props.DataCaptureDisabled);
    this.ImageEnabled = asBoolean(props.ImageEnabled);
    this.Buttons = mapArray(props.Buttons, (x) => new ButtonDataModel(x));
  }

  static ButtonIds = {
    backToHub: 'BACK_TO_HUB',
    playAgain: 'PLAY_AGAIN'
  };
  static BackToHubButton = new ButtonDataModel({
    id: this.ButtonIds.backToHub,
    text: 'Back to hub'
  });

  static PlayAgainButton = new ButtonDataModel({
    id: this.ButtonIds.playAgain,
    text: 'Play again'
  });

  static resolveButton(
    site: Site,
    properties: SitePageProperties,
    button: ButtonDataModel,
    options?: GetButtonOptions
  ) {
    return site.resolveButton({
      properties,
      color: button.color || options?.fallbackColor,
      backgroundColor:
        button.backgroundColor || options?.fallbackBackgroundColor,
      text: button.text || options?.fallbackText,
      url: button.url
    });
  }
}

interface GetButtonOptions {
  fallbackBackgroundColor?: string;
  fallbackColor?: string;
  fallbackText?: string;
}

export class QuestionCorrectFilter {
  MinCorrectAnswers?: number;
  MaxCorrectAnswers?: number;

  constructor(props?: Partial<QuestionCorrectFilter>) {
    Object.assign(this, props);
    this.MinCorrectAnswers = asNumberOrUndefined(props?.MinCorrectAnswers);
    this.MaxCorrectAnswers = asNumberOrUndefined(props?.MaxCorrectAnswers);
  }
}

export class CardResultProbabilityModel {
  winProbabilityNumerator: number;
  winProbabilityDenominator: InstantWinProbabilityDenominatorsType;
  maxWinners: number;
  isUnlimited: boolean;
  isFallback: boolean;

  constructor(props?: Partial<CardResultProbabilityModel>) {
    props = props || {};
    Object.assign(this, props);
    this.winProbabilityNumerator = asNumber(props.winProbabilityNumerator, 0);
    this.winProbabilityDenominator = asNumber(
      props.winProbabilityDenominator,
      100
    ) as any;
    this.maxWinners = asNumber(props.maxWinners, 1);
    this.isUnlimited = asBoolean(props.isUnlimited, true);
    this.isFallback = asBoolean(props.isFallback);
  }

  getWinPercentage() {
    return (
      asFloat(this.winProbabilityNumerator / this.winProbabilityDenominator) *
      100
    );
  }

  getRecipientsPercentage(recipients: number) {
    const percentage = asFloat(recipients / this.maxWinners) * 100;

    return `${percentage.toFixed(0)}%`;
  }
}
