import { isArray } from 'lodash/fp';

import { isNil } from '@/common/utils/TypeFunctions';

import { CanvasBackground } from './CanvasBackground';
import { CanvasCreationChoices } from './CanvasCreationChoices';
import { CanvasItem } from './CanvasItem';

export const getCanvasItems = (json: string) => {
  const fallback: CanvasItem[] = [];
  try {
    const items = JSON.parse(json);
    return isArray(items) ? items.map((x) => new CanvasItem(x)) : fallback;
  } catch (error) {
    console.error(error);
    return fallback;
  }
};

export const getCanvasCreationChoices = (json: string) => {
  const fallback = new CanvasCreationChoices();
  try {
    if (!json) return fallback;
    return new CanvasCreationChoices(JSON.parse(json));
  } catch (error) {
    console.error(error);
    return fallback;
  }
};

export const getCanvasBackground = (json: string) => {
  const fallback = new CanvasBackground({ color: '#FFFFFF' });
  try {
    if (!json) return fallback;
    return new CanvasBackground(JSON.parse(json));
  } catch (error) {
    console.error(error);
    return fallback;
  }
};

export const sanitiseInteractiveText = (
  items: CanvasItem[],
  interactiveText?: Record<string, string>
) => {
  if (!items.length) return [];
  const sanitised = items.map((x) => new CanvasItem(x));
  if (isNil(interactiveText)) return sanitised;
  Object.keys(interactiveText).forEach((id) => {
    if ((interactiveText[id] || '').trim()) {
      const index = sanitised.findIndex((x) => x.id === id);
      if (index >= 0 && sanitised[index].isInteractiveTextItem()) {
        sanitised[index].textData.text = interactiveText[id];
      }
    }
  });

  return sanitised;
};
