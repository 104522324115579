import { CardTypes } from '@/common/models/CardTypes';
import { Guid } from '@/common/models/Guid';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';
import { SiteCardProperties } from '@/common/models/SiteCardProperties';

import { ImageDataModel } from '../ImageDataModel';

export class Fact implements SiteCard {
  /**
   * The unique ID of the card.
   */
  id: Guid;

  /**
   * The ID of the site the card belongs to.
   */
  siteId: Guid;

  /**
   * The type of card.
   */
  readonly type: CardTypes = CardTypes.Fact;

  /**
   * The (internal) name of the card.
   */
  name: string;

  /**
   * The fact capture like 'Did you know?'.
   */
  caption: string = '';

  /**
   * The fact text.
   */
  text: string;

  /**
   * The background image of the fact.
   */
  imageUrl: string = '';

  get Image(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.imageUrl);
  }

  /**
   * The properties of the card.
   */
  properties: SiteCardProperties;

  constructor(props?: Partial<Fact>) {
    props = props || {};
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);
  }
}
