import { HeightWidth } from './HeightWidth';
import { ImageDataModel } from './ImageDataModel';

export class CardMetadata {
  title: string;
  description: string;
  imageUrl: string;

  get Image(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.imageUrl);
  }

  imageSize?: HeightWidth;

  fallbackTitle?: string;
  fallbackDescription?: string;
  fallbackImageUrl?: string;

  get FallbackImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.fallbackImageUrl);
  }

  fallbackImageSize?: HeightWidth;
  embedData?: EmbedData;

  constructor(props: Partial<CardMetadata> = {}) {
    Object.assign(this, props);
  }
}

class EmbedData {
  embedUrl?: string;
  embedImage?: string;

  get EmbedImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.embedImage);
  }

  buttonStyle?: ButtonStyling;

  constructor(props: Partial<EmbedData> = {}) {
    Object.assign(this, props);
  }
}

class ButtonStyling {
  text?: string;
  backgroundColor?: string;
  color?: string;
}
