import { FrontTreasureHunt } from '../Front/FrontTreasureHunt';
import { FrontTreasureHuntCheckpoint } from '../Front/FrontTreasureHuntCheckpoint';
import { FrontTreasureHuntGameplay } from '../Front/FrontTreasureHuntGameplay';

export enum TreasureHuntCheckpointStatuses {
  Open = 'Open',
  Completed = 'Completed',
  Locked = 'Locked'
}

export const resolveTreasureHuntCheckpointStatus = (
  checkpoint: FrontTreasureHuntCheckpoint,
  card: FrontTreasureHunt,
  gameplay: FrontTreasureHuntGameplay
): TreasureHuntCheckpointStatuses => {
  if (gameplay.isCheckpointCompleted(checkpoint.id)) {
    return TreasureHuntCheckpointStatuses.Completed;
  }

  if (card.isCheckpointLocked(checkpoint, gameplay)) {
    return TreasureHuntCheckpointStatuses.Locked;
  }

  return TreasureHuntCheckpointStatuses.Open;
};
