import { SiteCardProperties } from '@/common/models/SiteCardProperties';

import { ImageDataModel } from '../../ImageDataModel';

export class UploadProperties implements SiteCardProperties {
  ButtonTextColor?: string;
  ButtonBackgroundColor?: string;
  CoverImageUrl: string;

  get CoverImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.CoverImageUrl);
  }

  constructor(props?: Partial<UploadProperties>) {
    props = props || {};
    Object.assign(this, props);
  }
}
