import { QueryKey, useQueryClient } from '@tanstack/react-query';
import isFunction from 'lodash/isFunction';
import isEqual from 'react-fast-compare';

import { useEvent } from '@/common/hooks/useEvent';
import { useLatest } from '@/common/hooks/useLatest';
import { useOnMount } from '@/common/hooks/useOnMount';

export interface ReactQuerySyncRequestItem {
  name: string;
  cacheKey: QueryKey | ((key: QueryKey) => boolean);
  callback?: (data: unknown) => void;
}

interface Options {
  items: ReactQuerySyncRequestItem[];
  onChange?: (name: string, data: unknown) => void;
}

export const useReactQuerySync = ({ items, onChange }: Options) => {
  const queryClient = useQueryClient();
  const itemsRef = useLatest(items);
  const onChangeRef = useEvent(onChange);

  useOnMount(() => {}, {
    resolveContextData: () => {
      const unsubscribe = queryClient
        .getQueryCache()
        .subscribe(({ type, query }) => {
          if (type !== 'added' && type !== 'updated') {
            return;
          }

          const data = query.state.data;

          if (!data) {
            return;
          }

          (itemsRef.current || []).forEach((item) => {
            const equalityPredicate = isFunction(item.cacheKey)
              ? item.cacheKey
              : (k: QueryKey) => isEqual(k, item.cacheKey);

            if (equalityPredicate(query.queryKey)) {
              item.callback?.(data);
              onChangeRef?.(item.name, data);
            }
          });
        });

      return {
        unsubscribe
      };
    },
    cleanup: ({ unsubscribe }) => {
      unsubscribe();
    }
  });
};
