import addSeconds from 'date-fns/addSeconds';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isEqual from 'date-fns/isEqual';

import { Guid } from '@/common/models/Guid';
import { ImageDataModel } from '@/common/models/ImageDataModel';
import { utcDate, utcDateOrUndefined } from '@/common/utils/DateFunctions';

import { TriviaGameProperties } from '../../shared/TriviaGameProperties';
import { IGameAnswersScreen } from './IGameAnswersScreen';
import { IGameLobbyScreen } from './IGameLobbyScreen';
import { IGameProcessingScreen } from './IGameProcessingScreen';
import { IGameQuizScreen } from './IGameQuizScreen';
import { IGameRulesScreen } from './IGameRulesScreen';
import { IGameWinnerScreen } from './IGameWinnerScreen';
import { LiveGameplayStatuses } from './LiveGameplayStatuses';
import { LiveGameStatuses } from './LiveGameStatuses';
import { LiveQuizQuestionScreen } from './LiveQuizQuestionScreen';
import { LiveScreenType } from './LiveScreenType';
import { LiveScreenTypes } from './LiveScreenTypes';

export class LiveGame {
  id: Guid;
  status: LiveGameStatuses = LiveGameStatuses.Live;
  name: string = '';
  description: string = '';
  coverUrl?: string;
  get coverImage() {
    return ImageDataModel.fromJsonOrUrl(this.coverUrl);
  }

  winnerId?: Guid;
  backgroundUrl?: string;

  get backgroundImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.backgroundUrl);
  }

  startedAt?: Date;
  prizesAwardedAt?: Date;
  prizeStatus: PrizeAwardStatuses = PrizeAwardStatuses.Pending;
  updatedAt: Date;

  secondsGameplay = 60 * 60;

  screens: LiveScreenType[] = [];

  jumbotronNotStartedUrl: string;
  get jumbotronNotStartedImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.jumbotronNotStartedUrl);
  }

  jumbotronGameplayUrl: string;
  get jumbotronGameplayImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.jumbotronGameplayUrl);
  }

  jumbotronProcessingUrl: string;
  get jumbotronProcessingImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.jumbotronProcessingUrl);
  }

  jumbotronWinnerUrl: string;
  get jumbotronWinnerImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.jumbotronWinnerUrl);
  }

  isInviteOnly: boolean = false;

  properties: TriviaGameProperties;

  constructor(props?: Partial<LiveGame>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrEmpty(props.id);
    this.winnerId = Guid.valueOrUndefined(props.winnerId);
    this.updatedAt = utcDate(props.updatedAt, () => new Date(1970, 1, 1));
    this.startedAt = utcDateOrUndefined(props.startedAt);
    this.prizesAwardedAt = utcDateOrUndefined(props.prizesAwardedAt);
    this.properties = new TriviaGameProperties(props.properties);
  }

  get isQuizStarted() {
    if (!this.startedAt) {
      return false;
    }

    const now = new Date();
    return isAfter(now, this.startedAt);
  }

  get isQuizEnded() {
    if (!this.startedAt) {
      return false;
    }

    return isAfter(
      new Date(),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      addSeconds(this.startedAt!, this.secondsGameplay)
    );
  }

  /**
   * Indicates if a winner has been selected.
   */
  get hasWinner() {
    return (
      this.winnerId !== undefined ||
      this.prizesAwardedAt ||
      this.properties.AnswersRevealedAt
    );
  }

  calculateStatus(
    now?: Date,
    options?: {
      ignoreStatuses: boolean;
    }
  ) {
    if (!now) {
      now = new Date();
    }

    if (this.prizesAwardedAt) {
      return LiveGameplayStatuses.Prizes;
    }

    const startedAt = this.startedAt;
    const ignoreStatuses = options?.ignoreStatuses ?? false;

    if (
      (this.status === LiveGameStatuses.Draft && !ignoreStatuses) ||
      !startedAt ||
      isAfter(startedAt, now)
    ) {
      return LiveGameplayStatuses.NotStarted;
    }

    if (!ignoreStatuses && this.status === LiveGameStatuses.Archived) {
      return LiveGameplayStatuses.Archived;
    }

    if (isBefore(now, addSeconds(startedAt, this.secondsGameplay))) {
      return LiveGameplayStatuses.Playing;
    }

    if (this.winnerId || this.properties.AnswersRevealedAt) {
      return LiveGameplayStatuses.Prizes;
    }

    return LiveGameplayStatuses.Processing;
  }

  public calculateGameOver() {
    return [
      LiveGameplayStatuses.Archived,
      LiveGameplayStatuses.Processing,
      LiveGameplayStatuses.Prizes
    ].includes(this.calculateStatus());
  }

  isOutOfDate(updated: LiveGame) {
    return !isEqual(new Date(this.updatedAt), new Date(updated.updatedAt));
  }

  isSocialSharingEnabled() {
    const facebook = this.properties.FacebookSharingDisabled;
    const messenger = this.properties.MessengerSharingDisabled;
    const whatsApp = this.properties.WhatsappSharingDisabled;
    const twitter = this.properties.TwitterSharingDisabled;
    const web = this.properties.WebsiteSharingDisabled;

    return !facebook || !messenger || !whatsApp || !twitter || !web;
  }

  getScreen<T extends LiveScreenType>(type: LiveScreenTypes) {
    return this.screens.find((screen) => screen.type === type) as T;
  }

  getRulesScreen() {
    return this.getScreen<IGameRulesScreen>(LiveScreenTypes.Rules);
  }

  getLobbyScreen() {
    return this.getScreen<IGameLobbyScreen>(LiveScreenTypes.Lobby);
  }

  getProcessingScreen() {
    return this.getScreen<IGameProcessingScreen>(LiveScreenTypes.Processing);
  }

  getFinishedScreen() {
    return this.getScreen<IGameWinnerScreen>(LiveScreenTypes.Finished);
  }

  getAnswersScreen() {
    return this.getScreen<IGameAnswersScreen>(LiveScreenTypes.Answers);
  }

  getWinnerScreen() {
    return this.getScreen<IGameWinnerScreen>(LiveScreenTypes.Winner);
  }

  getScreens<TScreen extends LiveScreenType>(type: LiveScreenTypes) {
    return this.screens.filter((s) => s.type === type) as TScreen[];
  }

  getQuestion(questionId: Guid): LiveQuizQuestionScreen {
    if (!questionId) {
      return undefined;
    }

    const gameQuestions = this.screens.filter(
      (s) => s.type === LiveScreenTypes.QuizQuestion
    ) as IGameQuizScreen[];
    const question = gameQuestions.find((q) => Guid.equals(questionId, q.id));
    if (!question) {
      return undefined;
    }

    return new LiveQuizQuestionScreen(question);
  }
}

enum PrizeAwardStatuses {
  Pending = 'Pending',
  Awarding = 'Awarding',
  Awarded = 'Awarded'
}
