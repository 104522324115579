import { asBoolean } from '@/common/utils/BooleanFunctions';
import { asNumber } from '@/common/utils/NumberFunctions';
import { theme } from '@/theme';

import { Guid } from '../Guid';
import { ImageDataModel } from '../ImageDataModel';

export class QuizBridge {
  id: Guid;
  text: string; //html string
  textEditorStateJson?: string;
  backgroundImageUrl: string =
    'https://images.unsplash.com/photo-1509023464722-18d996393ca8?ixid=MnwyMjk3Njh8MHwxfHNlYXJjaHwzfHxkYXJrfGVufDB8MHx8fDE2MzEwMDMzMjc&ixlib=rb-1.2.1&w=1920&h=1080';

  get backgroundImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.backgroundImageUrl);
  }

  videoUrl?: string;
  videoEnabled: boolean;
  videoTitle?: string;
  videoDescription?: string;

  backgroundColor?: string = theme.other.colors.black;
  textColor?: string = theme.other.colors.white;
  backgroundImageEnabled: boolean = true; //TODO: cli task to change to mediaEnabled and backgroundImageUrl to imageUrl
  textEnabled: boolean = true;
  order: number;

  get isBridge() {
    return true;
  }
  get hasVideo() {
    return this.videoEnabled && !!this.videoUrl;
  }

  get hasImage() {
    return this.backgroundImageEnabled && !!this.backgroundImage?.url;
  }

  constructor(props: Partial<QuizBridge> = {}) {
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.order = asNumber(props.order);
    this.backgroundImageEnabled = asBoolean(
      props?.backgroundImageEnabled,
      true
    );
    this.textEnabled = asBoolean(props?.textEnabled, false);
    this.videoEnabled = asBoolean(props?.videoEnabled, false);
  }

  parseTextHtml(defaultValue = '') {
    if (!this.text) return defaultValue;

    return (
      new DOMParser().parseFromString(this.text, 'text/html').documentElement
        .textContent || defaultValue
    );
  }
}
