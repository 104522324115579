import { immerable } from 'immer';
import sortBy from 'lodash/sortBy';

import { asBoolean } from '@/common/utils/BooleanFunctions';

import { CardCategories } from './CardCategories';
import { CardIdentifier, ICardIdentifier } from './CardIdentifier';
import { CardSubTypes } from './CardSubTypes';
import { CardTiers } from './CardTiers';
import { CardTypes } from './CardTypes';
import { OptionModel } from './OptionModel';

export class CardDefinition implements ICardIdentifier {
  [immerable] = true;
  type: CardTypes;
  subType?: CardSubTypes;
  name: string;
  explainer: string;
  description: string;
  category: CardCategories;
  tier: CardTiers;
  helpUrl: string;
  helpId?: string;
  helpSecondaryId?: string;
  order: number;
  isSuperUser: boolean;
  isBeta: boolean;
  identifier: CardIdentifier;
  docsLink?: string;
  designGuidelinesLink?: string;

  constructor(props?: Partial<CardDefinition>) {
    props = props || {};
    Object.assign(this, props);
    this.isSuperUser = asBoolean(props.isSuperUser);
    this.isBeta = asBoolean(props.isBeta);
    this.identifier = new CardIdentifier({
      type: props.type,
      subType: props.subType
    });
  }

  isMatch(identifier?: ICardIdentifier) {
    return this.identifier.equals(identifier);
  }
}

export class CardDefinitionOption implements OptionModel<string> {
  label: string;
  value: string;
  disabled?: boolean;
  description?: string;
  definition: CardDefinition;

  constructor(definition: CardDefinition) {
    this.label = definition.name;
    this.description = definition.description;
    this.definition = definition;
    this.value = definition.identifier.id;
  }

  static arrayFromDefinitions(
    definitions: CardDefinition[]
  ): CardDefinitionOption[] {
    return sortBy(
      definitions.map((x) => new CardDefinitionOption(x)),
      (x) => x.label.toLowerCase()
    );
  }
}
