import { CardTypes } from '@/common/models/CardTypes';
import { Guid } from '@/common/models/Guid';
import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';

import { ImageDataModel } from '../../ImageDataModel';
import { TriviaProperties } from '../shared';
import { LiveGame } from './Game';

export class FrontTrivia implements SiteCard {
  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.Trivia;
  name: string;
  gameId: Guid;
  properties: TriviaProperties;
  imageUrl: string = '';
  game: LiveGame;

  get CoverImage() {
    const image = ImageDataModel.fromJsonOrUrl(this.imageUrl);
    if (image) image.alt = 'cover';
    return image;
  }

  constructor(props?: Partial<FrontTrivia>) {
    props = props || {};
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);
    this.game = new LiveGame(props.game);
    this.properties = new TriviaProperties(props.properties);
  }

  getCoverProps(properties: SitePageProperties) {
    return {
      ...this.properties.getCoverButtonProps(properties),
      image: this.CoverImage
    };
  }
}
