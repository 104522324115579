import { nanoid } from 'nanoid';

import { Guid } from '@/common/models/Guid';
import { HeightWidth } from '@/common/models/HeightWidth';
import {
  arrayMoveMutable,
  mapArray,
  tryRemoveFromArray
} from '@/common/utils/ArrayFunctions';
import { asNumber } from '@/common/utils/NumberFunctions';
import { orderByAscending } from '@/common/utils/SortingFunctions';

import { SwiperOptionGroupProperties, SwiperOptionProperties } from '../shared';
import { AdminSwiperOption } from './AdminSwiperOption';

export class AdminSwiperOptionGroup {
  id: Guid;
  swiperId: Guid;
  name: string;
  order: number;
  options: AdminSwiperOption[];
  properties: SwiperOptionGroupProperties;

  get hasOptions() {
    return !!this.optionsLength;
  }

  get optionsLength() {
    return (this.options || []).length;
  }

  get aspectRatio(): HeightWidth {
    return {
      height: 1,
      width: 1
    };
  }

  constructor(props?: Partial<AdminSwiperOptionGroup>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.swiperId = Guid.valueOrNew(props.swiperId);
    this.order = asNumber(props.order, 1);
    this.options = mapArray(props.options, (x) => new AdminSwiperOption(x));
    this.sanitiseOptions();
    this.properties = new SwiperOptionGroupProperties(props.properties);
  }

  addOption(option: AdminSwiperOption) {
    option.order = 999999;
    this.options.push(option);
    this.sanitiseOptions();
  }

  updateOption(id: Guid, change: Partial<AdminSwiperOption>) {
    const index = this.options.findIndex((o) => o.id.equals(id));
    if (index < 0) return false;
    this.options[index] = new AdminSwiperOption({
      ...this.options[index],
      ...change
    });
    this.sanitiseOptions();
    return true;
  }

  updateOptionPropeties(id: Guid, change: Partial<SwiperOptionProperties>) {
    const index = this.options.findIndex((o) => o.id.equals(id));
    if (index < 0) return false;
    this.options[index] = new AdminSwiperOption({
      ...this.options[index],
      properties: new SwiperOptionProperties({
        ...this.options[index].properties,
        ...change
      })
    });
    this.sanitiseOptions();
    return true;
  }

  reorderOption(id: Guid, destinationIndex: number) {
    const sourceIndex = this.options.findIndex((o) => o.id.equals(id));
    if (sourceIndex < 0) return false;
    arrayMoveMutable(this.options, sourceIndex, destinationIndex);
    this.options.forEach((x, i) => {
      x.order = i;
    });
    return true;
  }

  deleteOption(id: Guid) {
    return tryRemoveFromArray(this.options, (x) => x.id.equals(id));
  }

  cloneOption(id: Guid) {
    const index = this.options.findIndex((o) => o.id.equals(id));
    if (index < 0) return false;
    const cloned = this.options[index].clone();
    this.addOption(cloned);
    return true;
  }

  sanitiseOptions() {
    this.options = this.options.sort(orderByAscending);
    this.options.forEach((o, i) => {
      o.order = i + 1;
    });
  }

  getOptionImageUploadPath(siteId: Guid, optionId?: Guid) {
    return `site/${siteId}/card/${this.swiperId}/group/${this.id}/option/${
      optionId || `temp-${nanoid()}`
    }`;
  }

  getExplainerImageUploadPath(siteId: Guid) {
    return `site/${siteId}/card/${this.swiperId}/group/${this.id}/explainer`;
  }

  getOptionNextTo(optionId: Guid, side: 'left' | 'right') {
    const length = this.options.length;
    if (length === 1) return this.options[0];
    const index = this.options.findIndex((o) => o.id.equals(optionId));
    if (index < 0) return null;
    const lastIndex = length - 1;
    if (side === 'left') {
      return index === 0 ? this.options[lastIndex] : this.options[index - 1];
    }

    return index === lastIndex ? this.options[0] : this.options[index + 1];
  }

  getOptionImages() {
    return this.options.map((x) => x.properties.Image).filter((x) => !!x?.url);
  }
}
