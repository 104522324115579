import { mapArray } from '@/common/utils/ArrayFunctions';
import { asBoolean } from '@/common/utils/BooleanFunctions';

import { ButtonDataModel } from '../../ButtonDataModel';
import { ImageDataModel } from '../../ImageDataModel';

export class FrontSpinToWinResult {
  title: string;
  description: string;
  image: ImageDataModel;
  hasForm: boolean;
  formCompleted: boolean;
  confettiEnabled: boolean;
  extraButtons: ButtonDataModel[];

  constructor(props?: Partial<FrontSpinToWinResult>) {
    props = props || {};
    Object.assign(this, props);
    this.image = ImageDataModel.fromJsonOrUrl(props.image);
    this.hasForm = asBoolean(props.hasForm);
    this.formCompleted = asBoolean(props.formCompleted);
    this.confettiEnabled = asBoolean(props.confettiEnabled);
    this.extraButtons = mapArray(
      props.extraButtons,
      (b) => new ButtonDataModel(b)
    );
  }

  setFormCompleted() {
    return new FrontSpinToWinResult({ ...this, formCompleted: true });
  }
}
