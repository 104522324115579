import { AssetTypes } from '@/common/models/AssetTypes';
import { CardTypes } from '@/common/models/CardTypes';
import { Guid } from '@/common/models/Guid';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';
import {
  FormFileAllowedType,
  toFormFileAllowedType
} from '@/common/utils/FileFunctions';

import { UploadProperties } from '../shared';

export class FrontUpload implements SiteCard {
  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.Upload;
  allowedTypes: AssetTypes[];
  properties: UploadProperties;

  constructor(props?: Partial<FrontUpload>) {
    props = props || {};
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);
    this.properties = new UploadProperties(props.properties);
  }

  getFormFileAllowedTypes() {
    if (!this.allowedTypes.length) {
      return [] as FormFileAllowedType[];
    }

    return this.allowedTypes
      .filter(toFormFileAllowedType)
      .map(toFormFileAllowedType);
  }
}
