import { SiteCardProperties } from '@/common/models/SiteCardProperties';
import { asFloat, asNumber } from '@/common/utils/NumberFunctions';

import { ImageDataModel } from '../ImageDataModel';

export class NumberGuessProperties implements SiteCardProperties {
  ButtonText?: string;
  DefaultButtonText: string = 'CONFIRM YOUR GUESS';
  ButtonTextColor?: string;
  ButtonBackgroundColor?: string;
  SliderImage?: string;

  get NumberGuessSliderImage() {
    return ImageDataModel.fromJsonOrUrl(this.SliderImage);
  }

  Decimals?: number;
  AnswerSuffix?: string;
  IncrementBy?: number;

  constructor(props: Partial<NumberGuessProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.Decimals = asNumber(props.Decimals, 0);
    this.IncrementBy = asFloat(props.IncrementBy, 1);
  }
}
