import { FrontAnyCard } from '@/common/models/AnyCard';
import { CardDefinition } from '@/common/models/CardDefinition';
import { CardDescriptor } from '@/common/models/CardDescriptor';
import { FrontCardFactory } from '@/common/models/CardFactory';
import { CardMetadata } from '@/common/models/CardMetadata';
import { CardTypes } from '@/common/models/CardTypes';
import { CardViewModel } from '@/common/models/CardViewModel';
import { Guid } from '@/common/models/Guid';
import { mapArray } from '@/common/utils/ArrayFunctions';
import { QueryParams } from '@/common/utils/QueryParams';
import { PublicLiveApi } from '@/front/data/PublicApi';

import { CardDescriptorSearchQuery } from './_types';
import { getCardRoute } from './_utils';

export const PublicCardActions = {
  getDefinitionsAsync: async () =>
    mapArray(
      await PublicLiveApi.get<CardDefinition[]>('cards/definitions'),
      (x) => new CardDefinition(x)
    ),

  async getTypedAsync<T extends FrontAnyCard>(
    id: Guid | string,
    type: CardTypes
  ): Promise<T> {
    return FrontCardFactory.map(
      type,
      await PublicLiveApi.get<T>(`${getCardRoute(type)}/${id}`)
    ) as T;
  },

  getMetadataAsync: async (cardId: Guid) =>
    new CardMetadata(await PublicLiveApi.get(`cards/${cardId}/meta`)),

  getResultMetadataAsync: async (cardId: Guid, gameplayId: Guid) =>
    new CardMetadata(
      await PublicLiveApi.get(`cards/${cardId}/results/${gameplayId}/meta`)
    ),

  searchAsync: (siteId: Guid, { take, skip }: CardDescriptorSearchQuery) => {
    return PublicLiveApi.getPage(
      `sites/${siteId}/cards${new QueryParams()
        .setTakeSkip(take, skip)
        .toString()}`,
      (x) => new CardDescriptor(x)
    );
  },

  getDescriptorAsync: async (cardId: Guid) => {
    return new CardDescriptor(
      await PublicLiveApi.get<CardDescriptor>(`cards/${cardId}`)
    );
  },

  getViewModelAsync: async (cardId: Guid) => {
    return new CardViewModel(
      await PublicLiveApi.get<CardViewModel>(`cards/${cardId}/view-model`)
    );
  },

  triggerGameplayInstantResultAsync: (request: {
    cardId: Guid | string;
    gameplayId: Guid | string;
  }) => {
    return PublicLiveApi.post(
      `cards/${request.cardId}/gameplays/${request.gameplayId}/trigger-instant-result`
    );
  }
};
