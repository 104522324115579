import { mapArray } from '@/common/utils/ArrayFunctions';

import { Guid } from './Guid';
import { OptionModel } from './OptionModel';
import { TagScopes } from './TagScopes';

export interface TagOptionModel extends OptionModel<string> {
  color: string;
}

export class Tag {
  id: Guid;
  name: string = '';
  scope: TagScopes;
  cardIds: Guid[];
  properties: TagProperties;

  get backgroundColor() {
    return this.properties.BackgroundColor;
  }

  constructor(props?: Partial<Tag>) {
    props ||= {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.cardIds = mapArray(props.cardIds || [], (x) => new Guid(x));
    this.properties = new TagProperties(props.properties);
  }

  isCardTagged(id: Guid) {
    return this.cardIds.some((x) => x.equals(id));
  }

  toOptionModel(): TagOptionModel {
    return {
      color: this.backgroundColor,
      label: this.name,
      value: this.id.toString()
    };
  }
}

export class TagProperties {
  BackgroundColor?: string;

  constructor(props?: Partial<TagProperties>) {
    props ||= {};
    Object.assign(this, props);
  }
}
