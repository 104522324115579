import { Guid } from '@/common/models/Guid';

/**
 * @deprecated use Guid.valueOrUndefined instead
 * Returns the value as a Guid or an undefined if not a valid Guid or undefined.
 * @param value The Guid value or an undefined.
 */
export const guidOrUndefined = (value: Guid | string | undefined) =>
  Guid.valueOrUndefined(value);

/**
 * @deprecated use Guid.valueOrNew instead
 * Returns the value as a Guid or an new Guid if not a valid Guid or undefined.
 * @param value The Guid value or an new Guid.
 */
export const guidOrNew = (value: Guid | string | undefined) =>
  Guid.valueOrNew(value);

/**
 * @deprecated use Guid.valueOrEmpty instead
 * Returns the value as a Guid or an empty Guid if not a valid Guid or undefined.
 * @param value The Guid value or an empty Guid.
 */
export const guidOrEmpty = (value: Guid | string | undefined) =>
  Guid.valueOrEmpty(value);
