import { asBoolean } from '@/common/utils/BooleanFunctions';

export class QuizResultProperties {
  PlayAgainDisabled: boolean;
  descriptionLexicalStateJson?: string;

  constructor(props?: Partial<QuizResultProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.PlayAgainDisabled = asBoolean(props.PlayAgainDisabled);
  }
}
