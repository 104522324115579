import { SiteCardProperties } from '@/common/models/SiteCardProperties';
import { asBoolean } from '@/common/utils/BooleanFunctions';

export class ChecklistProperties implements SiteCardProperties {
  ButtonTextColor?: string;
  ButtonBackgroundColor?: string;
  ReplayEnabled: boolean;

  constructor(props: Partial<ChecklistProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.ReplayEnabled = asBoolean(props.ReplayEnabled, false);
  }
}
