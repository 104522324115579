import { useEffect, useRef } from 'react';

interface Options<T = unknown> {
  resolveContextData?: () => T;
  cleanup?: (context: T) => void;
}

export function useOnMount<T = unknown>(
  callback: (context?: T) => void,
  options?: Options<T>
) {
  const mounted = useRef(false);

  useEffect(() => {
    const context = options?.resolveContextData?.();

    if (!mounted.current) {
      mounted.current = true;
      callback(context);
    }

    return () => options?.cleanup?.(context);
  }, []);
}
