import { nanoid } from 'nanoid';

import { ButtonDataModel } from '@/common/models/ButtonDataModel';
import { Guid } from '@/common/models/Guid';
import { ImageDataModel } from '@/common/models/ImageDataModel';
import { SiteCardProperties } from '@/common/models/SiteCardProperties';
import { mapArray } from '@/common/utils/ArrayFunctions';
import { asBoolean } from '@/common/utils/BooleanFunctions';
import { asNumber } from '@/common/utils/NumberFunctions';

import { WheelFrameTypes } from './WheelFrames';

export class SpinToWinProperties implements SiteCardProperties {
  ButtonTextColor?: string;
  ButtonBackgroundColor?: string;

  ModalHeaderColor: string = '#FFFFFF';
  ModalHeaderBackgroundColor: string = '#000000';
  ModalHeaderImageJson?: string;
  WheelBorderColor?: string = '#000000';
  WedgeBorderColor?: string = '#000000';
  WheelBorderFrame?: WheelFrameTypes = 'None';
  WheelRadiusLineWidth?: number = 5;
  OuterBorderWidth?: number = 5;

  WheelTickerColor?: string;
  WheelTickerOffsetColor?: string;

  WheelSpinnerText?: string;
  WheelSpinnerSubText?: string;

  WheelImageJson?: string;
  WedgeColorsJson?: string;

  WheelButtonColor: string = '#FFFFFF';
  WheelButtonBgColor: string = '#000000';

  get WheelImage() {
    return ImageDataModel.fromJsonOrUrl(this.WheelImageJson);
  }

  get ModalHeaderImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.ModalHeaderImageJson);
  }

  ModalBackgroundImageJson?: string;

  get ModalBackgroundImage() {
    return ImageDataModel.fromJsonOrUrl(this.ModalBackgroundImageJson);
  }

  ModalBackgroundColor: string = '#FFFFFF';
  ModalConfirmButtonText?: string;
  ModalTextColor: string = '#000000';

  static DefaultExplainerCta = new ButtonDataModel({
    backgroundColor: '#000000',
    color: '#FFFFFF',
    text: "Let's go!"
  });

  get ModalHeaderProps() {
    return {
      color: this.ModalHeaderColor,
      backgroundColor: this.ModalHeaderBackgroundColor,
      image: this.ModalHeaderImage
    };
  }

  get ModalBackgroundProps() {
    return {
      image: this.ModalBackgroundImage,
      backgroundColor: this.ModalBackgroundColor
    };
  }

  WheelWedgesJson?: string;

  get WheelWedges(): WheelWedge[] {
    if (!this.WheelWedgesJson) {
      return [];
    }
    const wheelWedges = JSON.parse(this.WheelWedgesJson);
    return mapArray(wheelWedges, (w) => new WheelWedge(w));
  }

  constructor(props: Partial<SpinToWinProperties> = {}) {
    Object.assign(this, props);
  }

  get WedgeColorsArray(): WedgeColor[] {
    if (!this.WedgeColorsJson) {
      return [
        { id: nanoid(5), text: '#ffffff', background: '#FF0000' },
        { id: nanoid(5), background: '#ffffff', text: '#FF0000' }
      ];
    }
    return JSON.parse(this.WedgeColorsJson);
  }
}

export type WedgeColor = { id: string; text: string; background: string };

export class WheelWedge {
  backgroundColor?: string;
  textColor?: string;
  overrideWheelColors?: boolean;
  text: string;
  backgroundImage?: ImageDataModel;
  repeatNumber: number = 1;
  resultId: Guid;
  constructor(props: Partial<WheelWedge> = {}) {
    Object.assign(this, props);
    this.repeatNumber = asNumber(props.repeatNumber, 1);
    this.overrideWheelColors = asBoolean(props.overrideWheelColors);

    this.backgroundImage = ImageDataModel.fromJsonOrUrl(props.backgroundImage);

    if (props.overrideWheelColors == undefined && !!this.textColor) {
      this.overrideWheelColors = true;
    }

    this.resultId = Guid.valueOrUndefined(props.resultId);
  }
}
