import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { asBoolean } from '@/common/utils/BooleanFunctions';
import {
  getPrimaryBackgroundColor,
  getPrimaryTextColor
} from '@/common/utils/ButtonFunctions';
import { asModelOrFallback } from '@/common/utils/StringFunctions';
import { theme } from '@/theme';

import { ColorRGBA, colorRGBAOrUndefined } from '../ColorRGBA';
import { Guid } from '../Guid';
import { SiteCardProperties } from '../SiteCardProperties';
import { QuizBridge } from './QuizBridge';

export class QuizProperties implements SiteCardProperties {
  ButtonTextColor?: string;
  ButtonBackgroundColor?: string;

  HideAnswers?: boolean;
  HidePercentages?: boolean;

  CoverButtonText: string;
  CoverButtonTextColor?: ColorRGBA;
  CoverButtonBackgroundColor?: ColorRGBA;

  BridgeScreensJson?: string;

  NextButtonTextColor?: string;
  NextButtonBackgroundColor?: string;

  ReplayEnabled: boolean;
  ReplayButtonColor?: string;
  ReplayButtonText?: string;

  ViewAnswersEnabled: boolean;
  ViewAnswersButtonColor?: string;
  ViewAnswersButtonText?: string;

  QuestionHeaderBackgroundColor: string = theme.other.colors.white;
  QuestionHeaderTextColor: string = theme.black;
  QuestionHeaderNavigationActiveColor: string = theme.colors.gray[8];
  QuestionHeaderNavigationInactiveColor: string = theme.colors.gray[3];
  QuestionBodyBackgroundColor: string = theme.other.colors.white;
  QuestionBodyTextColor: string = theme.black;
  QuestionOptionBackgroundColor: string = theme.colors.gray[1];
  QuestionOptionFillColor: string = theme.colors.blue[3];
  QuestionOptionTextColor: string = theme.colors.gray[8];

  ResultHeaderBackgroundColor: string = theme.colors.gray[2];
  ResultHeaderTextColor: string = theme.black;
  ResultBodyBackgroundColor: string = theme.other.colors.white;
  ResultBodyTextColor: string = theme.black;

  get replayButtonColor() {
    return this.ReplayButtonColor;
  }

  get viewAnswersButtonColor() {
    return this.ViewAnswersButtonColor;
  }

  constructor(props: Partial<QuizProperties> = {}) {
    Object.assign(this, props);
    this.HideAnswers = asBoolean(props?.HideAnswers);
    this.HidePercentages = asBoolean(props?.HidePercentages);
    this.ReplayEnabled = asBoolean(props.ReplayEnabled);
    this.CoverButtonTextColor = colorRGBAOrUndefined(
      props.CoverButtonTextColor
    );
    this.CoverButtonBackgroundColor = colorRGBAOrUndefined(
      props.CoverButtonBackgroundColor
    );
    this.ViewAnswersEnabled = asBoolean(props.ViewAnswersEnabled, true);
  }

  getBridgeScreens(): QuizBridge[] {
    const screens = asModelOrFallback(this.BridgeScreensJson);
    return screens?.length ? screens.map((x) => new QuizBridge(x)) : [];
  }

  addBridgeScreen(order: number) {
    const screens = this.getBridgeScreens();
    const newBridge = new QuizBridge({ order });
    this.setBridgeScreens([...screens, newBridge]);
    return newBridge;
  }

  getBridgeScreen(screenId: Guid): QuizBridge | undefined {
    const match = this.getBridgeScreens().findIndex((s) =>
      s.id.equals(screenId)
    );
    return match === -1 ? undefined : this.getBridgeScreens()[match];
  }

  updateBridgeScreen(screenId: Guid, partial: Partial<QuizBridge>) {
    const newScreens = this.getBridgeScreens().map((r) => {
      if (r.id.equals(screenId)) {
        return new QuizBridge({
          ...r,
          ...partial
        });
      }
      return r;
    });
    this.setBridgeScreens(newScreens);
  }

  setBridgeScreens(screens: QuizBridge[]) {
    const screensJson = JSON.stringify(screens);
    this.BridgeScreensJson = screensJson;
  }

  deleteBridgeScreen(screenId: Guid) {
    const newScreens = this.getBridgeScreens().filter(
      (s) => !s.id.equals(screenId)
    );
    this.setBridgeScreens(newScreens);
  }

  getCoverButtonTextColorAsString(properties: SitePageProperties) {
    if (this.CoverButtonTextColor) return this.CoverButtonTextColor.toString();

    return getPrimaryTextColor({
      properties
    });
  }

  getCoverButtonBackgroundColorAsString(properties: SitePageProperties) {
    if (this.CoverButtonBackgroundColor)
      return this.CoverButtonBackgroundColor.toString();

    return getPrimaryBackgroundColor({
      properties
    });
  }

  getNextScreenButtonProps(properties: SitePageProperties) {
    return {
      backgroundColor:
        this.NextButtonBackgroundColor || properties?.AccentBackgroundColor,
      color: this.NextButtonTextColor || properties?.AccentTextColor
    };
  }
}
