import { asBoolean } from '@/common/utils/BooleanFunctions';

export class CompetitionProperties {
  ValidateFields?: string;
  HidePlayAgain?: boolean;

  constructor(props?: Partial<CompetitionProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.HidePlayAgain = asBoolean(props.HidePlayAgain, false);
  }
}
