import { CardTypes } from '@/common/models/CardTypes';
import { Guid } from '@/common/models/Guid';
import { LegacyTriviaLeaderboardProperties } from '@/common/models/legacy-trivia-leaderboard';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';
import { asBoolean } from '@/common/utils/BooleanFunctions';

export interface TriviaGameAdminModel {
  triviaGameId: Guid;
  name?: string;
  hasEmailFormField?: boolean;
}

export class LegacyTriviaLeaderboard implements SiteCard {
  public static DefaultEntries = 10;
  public static CollapsedSizes = [1, 3, 10];

  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.Leaderboard;
  name: string;
  games: TriviaGameAdminModel[];
  properties: LegacyTriviaLeaderboardProperties;
  heading: string = '';

  constructor(props?: Partial<LegacyTriviaLeaderboard>) {
    props = props || {};
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);
    this.games = (props.games || []).map<TriviaGameAdminModel>((x) => ({
      triviaGameId: Guid.valueOrEmpty(x?.triviaGameId),
      name: x?.name,
      hasEmailFormField: asBoolean(x?.hasEmailFormField)
    }));
    this.properties = new LegacyTriviaLeaderboardProperties(props.properties);
  }

  getCollapsedEntryCount(): number {
    // 20 is a legacy value, cap at 10
    return Math.min(
      this.properties.NumberOfTopEntries,
      LegacyTriviaLeaderboard.DefaultEntries
    );
  }

  getExpandedEntryCount(): number {
    switch (this.properties.NumberOfTopEntries) {
      case 1:
        return 10;
      case 3:
        return 10;
      default:
        return 20;
    }
  }

  getBackgroundColor() {
    return this.properties.getBackgroundColor();
  }

  getRankItemsColor() {
    return this.properties.getRankItemsColor();
  }

  getRankItemsBackgroundColor() {
    return this.properties.getRankItemsBackgroundColor();
  }

  getHeadingColor() {
    return this.properties.getHeadingColor();
  }
}
