import { immerable } from 'immer';

import { QrCodeFormValues } from '@/common/components/QrCode/QrCodeForm/QrCodeForm.types';
import { Guid } from '@/common/models/Guid';
import { ImageDataModel } from '@/common/models/ImageDataModel';
import { asBoolean } from '@/common/utils/BooleanFunctions';

import { TreasureHuntCheckpointStatuses } from './TreasureHuntCheckpointStatuses';

export class TreasureHuntCheckpointProperties {
  [immerable] = true;

  Title: string;
  Description: string;
  ImageDataModelJson?: string;

  SimulatedStatus: TreasureHuntCheckpointStatuses =
    TreasureHuntCheckpointStatuses.Open;

  TopContent: string;
  TopContentStateJson: string;
  TopContentEnabled: boolean;
  BottomContent: string;
  BottomContentStateJson: string;
  BottomContentEnabled: boolean;

  QrCodeId: Guid;
  QrCodeSettingsJson: string = '{}';

  TextCodeInputPlaceholder: string = 'Enter the code here';
  TextCodesJson: string = '[]';

  get QrCodeSettings(): QrCodeFormValues {
    const settings: QrCodeFormValues = JSON.parse(this.QrCodeSettingsJson);
    settings.image = ImageDataModel.fromJsonOrUrl(settings.image);

    return settings;
  }

  get TextCodes(): string[] {
    try {
      return JSON.parse(this.TextCodesJson);
    } catch (e) {
      return [];
    }
  }

  get Image(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.ImageDataModelJson);
  }

  constructor(props?: Partial<TreasureHuntCheckpointProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.TopContentEnabled = asBoolean(props.TopContentEnabled, true);
    this.BottomContentEnabled = asBoolean(props.BottomContentEnabled, true);
    this.QrCodeId = Guid.valueOrUndefined(props.QrCodeId);
  }
}
