import { Guid } from '@/common/models/Guid';

export class PickerTag {
  id: Guid;
  name: string;

  constructor(props: Partial<PickerTag> = {}) {
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
  }
}
