import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import {
  getPrimaryBackgroundColor,
  getPrimaryTextColor
} from '@/common/utils/ButtonFunctions';

import { CardDescriptor } from '../../CardDescriptor';
import { CardDescriptorProperties } from '../../CardDescriptorProperties';
import { CardTypes } from '../../CardTypes';
import { Guid } from '../../Guid';
import { ImageDataModel } from '../../ImageDataModel';
import { Site } from '../../site';
import { SiteCard } from '../../SiteCard';
import { SiteCardHelper } from '../../SiteCardHelper';
import { ImageProperties } from './ImageProperties';

export abstract class ImageBase<TImage extends ImageBase<TImage>>
  implements SiteCard
{
  static DefaultButtonText = 'Click here';

  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.Image;
  name: string;
  imageUrl: string = '';
  properties: ImageProperties;

  protected constructor(props?: Partial<TImage>) {
    props = props || {};
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);
    this.properties = new ImageProperties({
      isExternalUrl: true,
      ButtonEnabled: false,
      ButtonUrl: '',
      ButtonText: '',
      ButtonTextColor: '',
      ButtonBackgroundColor: '',
      ...props.properties
    });
  }

  get image() {
    return ImageDataModel.fromJsonOrUrl(this.imageUrl);
  }

  getButtonRenderProps(
    site: Site,
    cardDescriptor: CardDescriptor,
    properties: SitePageProperties
  ) {
    const { hasCompetition } = cardDescriptor;
    return site.resolveButton({
      properties,
      backgroundColor: getPrimaryBackgroundColor({ card: this, properties }),
      color: getPrimaryTextColor({ card: this, properties }),
      text: hasCompetition
        ? cardDescriptor.competition.form.properties.EntryButtonText ||
          CardDescriptorProperties.DefaultCompetitionButtonText
        : this.properties.ButtonText || 'Click here'
    });
  }
}
