import isNil from 'lodash/isNil';

import { Guid } from '@/common/models/Guid';
import { asBoolean } from '@/common/utils/BooleanFunctions';

import { SwiperResultProperties } from '../shared';

export class AdminSwiperResult {
  id: Guid;
  swiperId: Guid;
  isDefault: boolean;
  name: string;
  properties: SwiperResultProperties;

  constructor(props?: Partial<AdminSwiperResult>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.swiperId = Guid.valueOrNew(props.swiperId);
    this.isDefault = asBoolean(props.isDefault);
    this.properties = new SwiperResultProperties(props.properties);
  }

  getUploadPath(siteId: Guid) {
    return `site/${siteId}/card/${this.swiperId}/result/${this.id}`;
  }

  isValid(): ValidResult {
    const missingImage = !this.properties.Image;
    const missingTrigger =
      !this.isDefault &&
      (isNil(this.properties.MinCorrectAnswers) ||
        isNil(this.properties.MaxCorrectAnswers));
    return {
      isValid: !missingImage && !missingTrigger,
      missingImage,
      missingTrigger
    };
  }
}

interface ValidResult {
  isValid: boolean;
  missingImage: boolean;
  missingTrigger;
}
