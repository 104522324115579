import { FrontTreasureHunt } from '@/common/models/treasure-hunt/Front/FrontTreasureHunt';

import { AdminAnyCard, FrontAnyCard } from './AnyCard';
import { AdminCanvas } from './canvas';
import { FrontCanvas } from './canvas/Front';
import { CardTypes } from './CardTypes';
import { AdminCarousel } from './carousel';
import { FrontCarousel } from './carousel/Front';
import { Checklist } from './checklist';
import { CountdownClock } from './countdown-clock';
import { Embed } from './embed';
import { Fact } from './fact';
import { AdminImage } from './image';
import { FrontImage } from './image/Front';
import { AdminInstantWin } from './instant-win/Admin';
import { FrontInstantWin } from './instant-win/Front';
import { LegacyTriviaLeaderboard } from './legacy-trivia-leaderboard';
import { AdminLiveSurvey } from './live-survey/Admin';
import { FrontLiveSurvey } from './live-survey/Front';
import { AdminMemoryChallenge } from './Memory/Admin';
import { FrontMemoryChallenge } from './Memory/Front/FrontMemoryChallenge';
import { NumberGuess } from './number-guess';
import { AdminPicker } from './picker';
import { FrontPicker } from './picker/Front';
import { Predictive } from './predictive';
import { Quiz } from './quiz';
import { AdminScratchAndWin } from './scratch-and-win';
import { FrontScratchAndWin } from './scratch-and-win/Front';
import { AdminSpinToWin } from './spin-to-win';
import { FrontSpinToWin } from './spin-to-win/Front';
import { AdminSwiper } from './swiper';
import { FrontSwiper } from './swiper/Front';
import { AdminTreasureHunt } from './treasure-hunt/Admin/AdminTreasureHunt';
import { AdminTrivia } from './trivia/Admin/AdminTrivia';
import { FrontTrivia } from './trivia/Front';
import { AdminUpload } from './upload';
import { FrontUpload } from './upload/Front';
import { Video } from './video';

export const FrontCardFactory = {
  map(type: CardTypes, card: FrontAnyCard): FrontAnyCard {
    switch (type) {
      case CardTypes.Image:
        return new FrontImage(card as FrontImage);
      case CardTypes.Video:
        return new Video(card as Video);
      case CardTypes.Fact:
        return new Fact(card as Fact);
      case CardTypes.Checklist:
        return new Checklist(card as Checklist);
      case CardTypes.Quiz:
        return new Quiz(card as Quiz);
      case CardTypes.Trivia:
        return new FrontTrivia(card as FrontTrivia);
      case CardTypes.NumberGuess:
        return new NumberGuess(card as NumberGuess);
      case CardTypes.Embed:
        return new Embed(card as Embed);
      case CardTypes.Countdown:
        return new CountdownClock(card as CountdownClock);
      case CardTypes.Leaderboard:
        return new LegacyTriviaLeaderboard(card as LegacyTriviaLeaderboard);
      case CardTypes.Predictive:
        return new Predictive(card as Predictive);
      case CardTypes.Canvas:
        return new FrontCanvas(card as FrontCanvas);
      case CardTypes.Upload:
        return new FrontUpload(card as FrontUpload);
      case CardTypes.ScratchAndWin:
        return new FrontScratchAndWin(card as FrontScratchAndWin);
      case CardTypes.Carousel:
        return new FrontCarousel(card as FrontCarousel);
      case CardTypes.InstantWin:
        return new FrontInstantWin(card as FrontInstantWin);
      case CardTypes.Picker:
        return new FrontPicker(card as FrontPicker);
      case CardTypes.Swiper:
        return new FrontSwiper(card as FrontSwiper);
      case CardTypes.MemoryChallenge:
        return new FrontMemoryChallenge(card as FrontMemoryChallenge);
      case CardTypes.SpinToWin:
        return new FrontSpinToWin(card as FrontSpinToWin);
      case CardTypes.LiveSurvey:
        return new FrontLiveSurvey(card as FrontLiveSurvey);
      case CardTypes.TreasureHunt:
        return new FrontTreasureHunt(card as FrontTreasureHunt);
      default:
        const unsupported: never = type;
        throw new Error(`Card type ${unsupported} not supported yet`);
    }
  }
};

export const AdminCardFactory = {
  map(type: CardTypes, card: AdminAnyCard): AdminAnyCard {
    switch (type) {
      case CardTypes.Image:
        return new AdminImage(card as AdminImage);
      case CardTypes.Video:
        return new Video(card as Video);
      case CardTypes.Fact:
        return new Fact(card as Fact);
      case CardTypes.Checklist:
        return new Checklist(card as Checklist);
      case CardTypes.Quiz:
        return new Quiz(card as Quiz);
      case CardTypes.Trivia:
        return new AdminTrivia(card as AdminTrivia);
      case CardTypes.NumberGuess:
        return new NumberGuess(card as NumberGuess);
      case CardTypes.Embed:
        return new Embed(card as Embed);
      case CardTypes.Countdown:
        return new CountdownClock(card as CountdownClock);
      case CardTypes.Leaderboard:
        return new LegacyTriviaLeaderboard(card as LegacyTriviaLeaderboard);
      case CardTypes.Predictive:
        return new Predictive(card as Predictive);
      case CardTypes.Canvas:
        return new AdminCanvas(card as AdminCanvas);
      case CardTypes.Upload:
        return new AdminUpload(card as AdminUpload);
      case CardTypes.ScratchAndWin:
        return new AdminScratchAndWin(card as AdminScratchAndWin);
      case CardTypes.Carousel:
        return new AdminCarousel(card as AdminCarousel);
      case CardTypes.InstantWin:
        return new AdminInstantWin(card as AdminInstantWin);
      case CardTypes.Picker:
        return new AdminPicker(card as AdminPicker);
      case CardTypes.Swiper:
        return new AdminSwiper(card as AdminSwiper);
      case CardTypes.MemoryChallenge:
        return new AdminMemoryChallenge(card as AdminMemoryChallenge);
      case CardTypes.SpinToWin:
        return new AdminSpinToWin(card as AdminSpinToWin);
      case CardTypes.LiveSurvey:
        return new AdminLiveSurvey(card as AdminLiveSurvey);
      case CardTypes.TreasureHunt:
        return new AdminTreasureHunt(card as AdminTreasureHunt);
      default:
        const unsupported: never = type;
        throw new Error(`Card type ${unsupported} not supported yet`);
    }
  }
};
