import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';

import { CardDescriptor } from '../../CardDescriptor';
import { CompetitionEntryStyles } from '../../competitions';
import { Site } from '../../site';
import { ImageBase, ImageLinkStyles } from '../shared';

export class FrontImage extends ImageBase<FrontImage> {
  constructor(props?: Partial<FrontImage>) {
    super(props);
  }

  getRenderData(options: {
    site: Site;
    cardDescriptor: CardDescriptor;
    properties: SitePageProperties;
    competitionEntered?: boolean;
  }) {
    const linkStyle = this.properties.LinkStyle;
    const { cardDescriptor, site, competitionEntered, properties } = options;
    const hasButtonAction = linkStyle !== ImageLinkStyles.None;
    const { hasCompetition, competition } = cardDescriptor;
    const competitionClosed = hasCompetition && competition.isClosed();
    const isSingleEntry =
      competition?.entryStyle === CompetitionEntryStyles.One;
    const isMultiEntry = !isSingleEntry;

    const canEnterCompetition =
      hasCompetition &&
      (isMultiEntry || (isSingleEntry && !competitionEntered));

    return {
      image: this.getImageForRender(
        cardDescriptor,
        competitionClosed,
        competitionEntered
      ),
      linkStyle: linkStyle,
      showButton:
        this.properties.ButtonEnabled &&
        (hasButtonAction || canEnterCompetition),
      canEnterCompetition,
      hasLink: hasButtonAction,
      hasCompetition,
      button: this.getButtonRenderProps(site, cardDescriptor, properties),
      competitionClosed,
      competitionMessage: competitionClosed
        ? cardDescriptor.competition?.form?.properties?.ClosedMessage
        : competitionEntered
          ? cardDescriptor.competition?.form?.properties?.SubmittedMessage
          : undefined,
      showCompetitionMessage: competitionClosed
        ? cardDescriptor.competition?.form?.properties?.ClosedMessageEnabled
        : competitionEntered
          ? cardDescriptor.competition?.form?.properties
              ?.SubmittedMessageEnabled
          : undefined
    };
  }

  getImageForRender(
    cardDescriptor: CardDescriptor,
    competitionClosed: boolean,
    competitionEntered: boolean
  ) {
    if (competitionClosed) {
      return cardDescriptor.properties.ClosedCoverImage || this.image;
    }

    if (competitionEntered) {
      return cardDescriptor.properties.SubmittedCoverImage || this.image;
    }

    return this.image;
  }
}
