import { SiteCardProperties } from '@/common/models/SiteCardProperties';
import { asBoolean } from '@/common/utils/BooleanFunctions';

export class ImageProperties implements SiteCardProperties {
  isBanner: boolean;
  isExternalUrl: boolean;
  OpenExternalLinkInNewTab: boolean;
  ButtonEnabled: boolean;
  ButtonUrl?: string;
  ButtonText?: string;
  ButtonTextColor?: string;
  ButtonBackgroundColor?: string;
  LinkStyle?: ImageLinkStyles;

  constructor(props?: Partial<ImageProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.isBanner = asBoolean(props.isBanner, false);
    this.isExternalUrl = asBoolean(props.isExternalUrl, true);
    this.OpenExternalLinkInNewTab = asBoolean(
      props.OpenExternalLinkInNewTab,
      true
    );
    this.ButtonEnabled = asBoolean(props.ButtonEnabled, !this.isBanner);
    this.LinkStyle = props.LinkStyle || ImageLinkStyles.None;
  }
}

//needs to match enum ImageLinkStyles on the back end see: Image.cs
export enum ImageLinkStyles {
  None = 'None',
  Link = 'Link',
  Card = 'Card',
  ProfileDrawer = 'ProfileDrawer'
}
