import { Guid } from '@/common/models/Guid';

const baseKey = 'PublicCards';

export const PublicCardsCacheKeys = {
  definitions: [baseKey, 'Definitions'],
  siteCards: (siteId: Guid, pageId?: Guid) => [
    baseKey,
    'SiteCards',
    siteId.toString(),
    pageId?.toString()
  ],
  descriptor: (cardId: Guid | string) => [
    baseKey,
    'CardDescriptor',
    cardId.toString()
  ],
  typed: (cardId: Guid | string) => [baseKey, 'CardTyped', cardId?.toString()],
  metadata: (cardId: Guid) => [baseKey, 'CardMetadata', cardId?.toString()],
  gameplay: (cardId: Guid, gameplayId: Guid) => [
    baseKey,
    'CardGameplay',
    { cardId: cardId?.toString(), gameplayId: gameplayId?.toString() }
  ]
};
