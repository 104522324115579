import { ApiBase } from '@/common/data/ApiBase';
import { ApiHeaders } from '@/common/data/ApiHeaders';
import { ApiRequestConfig } from '@/common/data/ApiRequestConfig';
import { isServer } from '@/common/utils/NextFunctions';
import { SiteCache } from '@/front/utils/SiteCache';

const getPublicHeaders = (): HeadersInit => {
  const headers = {} as HeadersInit;
  const cacheState = SiteCache.getState();
  const url = cacheState.embeddedHostUrl;
  const userId = cacheState.userId;
  const pageId = cacheState.pageId;
  const pageSlug = cacheState.pageSlug;
  if (userId) {
    headers[ApiHeaders.UserSessionId] = userId;
  }
  if (url) {
    headers[ApiHeaders.EmbeddedHostUrl] = url;
  }
  if (pageId) {
    headers[ApiHeaders.PageId] = pageId;
  }
  if (pageSlug) {
    headers[ApiHeaders.PageSlug] = pageSlug;
  }

  if (isServer()) {
    headers[ApiHeaders.Ssr] = true;
  }
  return headers;
};

const buildConfig = (config: ApiRequestConfig = {}): ApiRequestConfig => {
  return {
    ...config,
    headers: {
      ...getPublicHeaders(),
      ...config.headers
    }
  };
};

/**
 * This class should not be used directly unless for testing.
 *
 * Use "PublicLiveApi"
 */
export class PublicApi extends ApiBase {
  public constructor(basePath: string) {
    super(basePath, buildConfig);
  }
}

export const PublicLiveApi = new PublicApi('api/v1/live');
