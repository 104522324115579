import { SiteCardProperties } from '@/common/models/SiteCardProperties';
import { asBoolean } from '@/common/utils/BooleanFunctions';
import {
  getPrimaryBackgroundColor,
  getPrimaryTextColor
} from '@/common/utils/ButtonFunctions';
import { theme } from '@/theme';

import { ButtonDataModel } from '../../ButtonDataModel';
import { ImageDataModel } from '../../ImageDataModel';
import { SitePageProperties } from '../../pages/shared/SitePageProperties';
import { InstantWinScreen } from './types';

export class InstantWinProperties implements SiteCardProperties {
  //SiteCardProperties
  ButtonTextColor?: string;
  ButtonBackgroundColor?: string;
  //end

  readonly DefaultCoverButtonText = 'PLAY TO WIN';
  CoverButtonText: string = this.DefaultCoverButtonText;
  CoverImageUrl?: string;

  get CoverImage() {
    return ImageDataModel.fromJsonOrUrl(this.CoverImageUrl);
  }

  CoverTitle?: string;

  LobbyTitle?: string;
  LobbyDescription?: string;
  LobbyDescriptionEditorStateJson?: string;
  LobbyImageUrl?: string;

  get LobbyImage() {
    return ImageDataModel.fromJsonOrUrl(this.LobbyImageUrl);
  }

  LobbyVideoUrl?: string;

  // instant win front modal
  readonly DefaultModalButtonText = 'Watch to win';
  BrandImageUrl?: string;

  get BrandImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.BrandImageUrl);
  }

  HeaderBackgroundColor: string = theme.other.colors.black;
  BackgroundColor?: string = theme.colors.gray[1];
  BackgroundImageUrl?: string;

  get BackgroundImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.BackgroundImageUrl);
  }

  BackgroundImageEnabled: boolean;
  TextColor: string = theme.other.colors.black;
  HeaderColor: string = theme.other.colors.white;
  ActiveTabColor: string = theme.other.colors.black;
  ModalButtonText: string = this.DefaultModalButtonText;
  ModalTitle?: string;
  ModalTitleEnabled: boolean;
  ModalButtonTextColor?: string;
  ModalButtonBackgroundColor?: string;
  LoadingImageUrl?: string;

  get LoadingImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.LoadingImageUrl);
  }

  LoadingText: string = 'Loading...';

  readonly DefaultBackToHubButtonText = 'Back to hub';
  BackToHubButtonText: string = this.DefaultBackToHubButtonText;
  BackToHubButtonTextColor: string;
  BackToHubButtonBackgroundColor: string;
  BackToHubButtonEnabled: boolean;

  readonly DefaultPlayAgainButtonText = 'Play again';
  PlayAgainButtonText: string = this.DefaultPlayAgainButtonText;
  PlayAgainButtonTextColor: string;
  PlayAgainButtonBackgroundColor: string;
  PlayAgainButtonDisabled: boolean;

  constructor(props: Partial<InstantWinProperties> = {}) {
    Object.assign(this, props);
    this.BackgroundImageEnabled = asBoolean(props.BackgroundImageEnabled);
    this.ModalTitleEnabled = asBoolean(props.ModalTitleEnabled, true);
    this.BackToHubButtonEnabled = asBoolean(props.BackToHubButtonEnabled, true);
    this.PlayAgainButtonDisabled = asBoolean(
      props.PlayAgainButtonDisabled,
      false
    );
  }

  getInstantWinScreen(): InstantWinScreen {
    return new InstantWinScreen({
      description: this.LobbyDescription,
      descriptionEditorStateJson: this.LobbyDescriptionEditorStateJson,
      title: this.LobbyTitle,
      imageUrl: this.LobbyImage?.url,
      videoUrl: this.LobbyVideoUrl
    });
  }

  getButtonTextColor(properties: SitePageProperties) {
    return getPrimaryTextColor({
      overrideColor: this.ButtonTextColor,
      properties
    });
  }

  getButtonBackgroundColor(properties: SitePageProperties) {
    return getPrimaryBackgroundColor({
      overrideColor: this.ButtonBackgroundColor,
      properties
    });
  }

  getBackgroundImage(properties: SitePageProperties) {
    if (!this.BackgroundImageEnabled) return undefined;
    return this.BackgroundImage || properties.BackgroundImageModel;
  }

  getModalTitle() {
    return this.ModalTitle || this.CoverTitle;
  }

  getModalBackgroundColor(properties: SitePageProperties) {
    return (
      this.BackgroundColor ||
      properties.BackgroundColor ||
      theme.other.colors.white
    );
  }

  getModalBackgroundProps(properties: SitePageProperties) {
    return {
      image: this.getBackgroundImage(properties),
      color: this.getModalBackgroundColor(properties),
      filterRgb: undefined
    };
  }

  getModalButtonBackgroundColor(properties: SitePageProperties) {
    return getPrimaryBackgroundColor({
      overrideColor: this.ModalButtonBackgroundColor,
      properties,
      fallback: '#000'
    });
  }

  getModalProps(properties: SitePageProperties) {
    const buttonBackgroundColor =
      this.getModalButtonBackgroundColor(properties);
    const buttonTextColor = this.getModalButtonTextColor(properties);
    const buttonText = this.ModalButtonText;
    const loadingText = this.LoadingText;
    const loadingImage = this.LoadingImage;
    return {
      buttonBackgroundColor,
      buttonTextColor,
      buttonText,
      loadingImage,
      loadingText
    };
  }

  getModalButtonTextColor(properties: SitePageProperties) {
    return getPrimaryTextColor({
      overrideColor: this.ModalButtonTextColor,
      properties,
      fallback: '#fff'
    });
  }

  getModalStyleProps(properties: SitePageProperties) {
    return {
      brandImage: this.BrandImage,
      headerBackgroundColor: this.HeaderBackgroundColor,
      headerColor: this.HeaderColor,
      modalBackgroundColor: this.getModalBackgroundColor(properties),
      modalBackgroundImage: this.getBackgroundImage(properties),
      modalTitle: this.getModalTitle(),
      modalTitleEnabled: this.ModalTitleEnabled
    };
  }

  getPlayAgainButtonBackgroundColor(properties: SitePageProperties) {
    return getPrimaryBackgroundColor({
      overrideColor: this.PlayAgainButtonBackgroundColor,
      properties
    });
  }

  getPlayAgainButtonTextColor(properties: SitePageProperties) {
    return getPrimaryTextColor({
      overrideColor: this.PlayAgainButtonTextColor,
      properties
    });
  }

  getBackToHubButtonBackgroundColor(properties: SitePageProperties) {
    return getPrimaryBackgroundColor({
      overrideColor: this.BackToHubButtonBackgroundColor,
      properties
    });
  }

  getBackToHubButtonTextColor(properties: SitePageProperties) {
    return getPrimaryTextColor({
      overrideColor: this.BackToHubButtonTextColor,
      properties
    });
  }

  getBackToHubButton(properties: SitePageProperties) {
    return new ButtonDataModel({
      backgroundColor: this.getBackToHubButtonBackgroundColor(properties),
      color: this.getBackToHubButtonTextColor(properties),
      text: this.BackToHubButtonText
    });
  }

  getPlayAgainButton(properties: SitePageProperties) {
    return new ButtonDataModel({
      backgroundColor: this.getPlayAgainButtonBackgroundColor(properties),
      color: this.getPlayAgainButtonTextColor(properties),
      text: this.PlayAgainButtonText
    });
  }
}
