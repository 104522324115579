import { Guid } from '@/common/models/Guid';
import { HeightWidth } from '@/common/models/HeightWidth';
import { ImageDataModel } from '@/common/models/ImageDataModel';
import { asBoolean } from '@/common/utils/BooleanFunctions';

import { ITriviaGameScreen } from './ITriviaGameScreen';
import { TriviaGameScreenTypes } from './TriviaGameScreenTypes';

export class TriviaGameQuestionScreen implements ITriviaGameScreen {
  public id: Guid;
  public type = TriviaGameScreenTypes.QuizQuestion;
  public questionType: TriviaQuestionTypes = TriviaQuestionTypes.Text;
  public questionText: string = '';
  public questionImageUrl: string = '';

  get QuestionImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.questionImageUrl);
  }

  public shuffleAnswers: boolean = true;

  /**
   * Note: order is not persisted currently.
   */
  public order: number = 1;
  public isSurvey: boolean = false;
  public seconds: number = 20;
  public answerType: TriviaAnswerTypes = TriviaAnswerTypes.Text;
  public answers: TriviaQuestionAnswer[] = [];

  hideQuestionText: boolean;
  propertyDefinitionId?: Guid;

  constructor(props?: Partial<TriviaGameQuestionScreen>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.shuffleAnswers = asBoolean(props.shuffleAnswers);
    this.isSurvey = asBoolean(props.isSurvey);
    this.answers = (props.answers || []).map(
      (x) => new TriviaQuestionAnswer(x)
    );
    this.hideQuestionText = asBoolean(props.hideQuestionText);
    this.propertyDefinitionId = Guid.valueOrUndefined(
      props.propertyDefinitionId
    );
  }

  isImage() {
    return this.questionType === TriviaQuestionTypes.TextAndImage;
  }

  getRenderProps() {
    return {
      isTwoUp: this.answers.length === 2 || this.answers.length >= 4,
      isFour: this.answers.length === 4,
      onlyImage: [TriviaAnswerTypes.Image].includes(this.answerType),
      onlyText: [TriviaAnswerTypes.Text].includes(this.answerType),
      hasImage: [
        TriviaAnswerTypes.TextAndImage,
        TriviaAnswerTypes.Image
      ].includes(this.answerType),
      hasText: [
        TriviaAnswerTypes.TextAndImage,
        TriviaAnswerTypes.Text
      ].includes(this.answerType)
    };
  }

  getImageUploadProps(gameId: Guid) {
    const size: HeightWidth = { height: 1080, width: 1920 };
    return {
      path: `live/${gameId}/question/${this.id}`,
      size
    };
  }

  addNewAnswer() {
    this.answers.push(
      new TriviaQuestionAnswer({
        answerText: 'Answer',
        isCorrect: false
      })
    );
  }

  getCorrectAnswer() {
    return this.answers.find((x) => x.isCorrect);
  }

  hasCorrectAnswer() {
    return this.isSurvey || this.answers.some((x) => x.isCorrect);
  }

  getInternalName() {
    return `${
      !!this.questionText
        ? this.questionText
        : this.QuestionImage?.url
          ? '(Image question)'
          : '(No Text)'
    }${this.isSurvey ? ' (Survey)' : ''}`;
  }
}

export class TriviaQuestionAnswer {
  id: Guid;
  answerText: string;
  answerImageUrl: string;

  get AnswerImage() {
    return ImageDataModel.fromJsonOrUrl(this.answerImageUrl);
  }

  isCorrect: boolean;

  constructor(props?: Partial<TriviaQuestionAnswer>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.isCorrect = asBoolean(props.isCorrect);
  }

  getImageUploadProps(gameId: Guid, questionId: Guid) {
    const size: HeightWidth = { height: 630, width: 630 };
    return {
      path: `live/${gameId}/question/${questionId}/answer/${this.id}`,
      size
    };
  }
}
export enum TriviaQuestionTypes {
  Text = 'Text',
  TextAndImage = 'TextAndImage'
}

export enum TriviaAnswerTypes {
  Text = 'Text',
  TextAndImage = 'TextAndImage',
  Image = 'Image'
}
