import isNil from 'lodash/isNil';

import { CardDescriptor } from '@/common/models/CardDescriptor';
import { Guid } from '@/common/models/Guid';
import { mapArray } from '@/common/utils/ArrayFunctions';
import { asBoolean } from '@/common/utils/BooleanFunctions';

import { CardDescriptorProperties } from '../../CardDescriptorProperties';
import { FrontCardResult } from '../../FrontCardResult';
import { FrontSwiper } from './FrontSwiper';
import { FrontSwiperOption } from './FrontSwiperOption';
import { FrontSwiperOptionGroup } from './FrontSwiperOptionGroup';
import { FrontSwiperResult } from './FrontSwiperResult';

export class FrontSwiperGameplay {
  id: Guid;
  swiperId: Guid;
  resultId?: Guid;
  competitionEntryId?: Guid;
  result?: FrontSwiperResult;
  cardResult?: FrontCardResult;
  userId: Guid;
  finishedAt?: Date;
  choices: FrontSwiperGameplayChoice[];
  properties: SwiperGameplayProperties;

  get hasCompetitionEntry() {
    return !!this.competitionEntryId;
  }

  get hasFinished() {
    return !!this.finishedAt;
  }

  get totalCorrect() {
    return this.choices.filter((x) => x.isCorrect).length;
  }

  constructor(props?: Partial<FrontSwiperGameplay>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.userId = Guid.valueOrNew(props.userId);
    this.swiperId = Guid.valueOrNew(props.swiperId);
    this.resultId = Guid.valueOrUndefined(props.resultId);
    this.competitionEntryId = Guid.valueOrUndefined(props.competitionEntryId);
    this.choices = mapArray(
      props.choices,
      (x) => new FrontSwiperGameplayChoice(x)
    );
    if (props.result) {
      this.result = new FrontSwiperResult(props.result);
    }
    if (props.cardResult) {
      this.cardResult = new FrontCardResult(props.cardResult);
    }
    this.properties = new SwiperGameplayProperties(props.properties);
  }

  getCurrentQuestion(card: FrontSwiper) {
    return card.optionGroups.find((x) => !this.isGroupFinished(x), this);
  }

  getUnFinishedGroups(card: FrontSwiper) {
    return card.optionGroups.filter((x) => !this.isGroupFinished(x), this);
  }

  isGroupFinished(group: FrontSwiperOptionGroup) {
    //If gameplay is finished then bail early
    if (this.hasFinished) {
      return true;
    }

    return group.options.every((x) => this.hasSwipedOption(x.id), this);
  }

  getSwipableOptions(group: FrontSwiperOptionGroup) {
    const options: FrontSwiperOption[] = [];
    const reversed: FrontSwiperOption[] = [];
    group.options.forEach((o) => {
      if (!this.hasSwipedOption(o.id)) {
        options.push(o);
        reversed.unshift(o);
      }
    }, this);

    //We reverse this to assist the front end when rendering
    return {
      options,
      reversed,
      first: options?.[0]
    };
  }

  canEnterCompetition(cardDescriptor: CardDescriptor) {
    const hasCardResult = !!this.cardResult;
    const hasResult = !!this.result;
    if (!hasCardResult && !hasResult) {
      return false;
    }

    const dataCaptureEnabled = hasCardResult
      ? !this.cardResult.properties.getLegacyContent().DataCaptureDisabled
      : !this.result.properties.DataCaptureDisabled;
    return (
      dataCaptureEnabled &&
      cardDescriptor.hasCompetition &&
      !cardDescriptor.properties.ShowCompetitionAtStart &&
      !this.hasCompetitionEntry
    );
  }

  getCurrentSection(): SwiperGameplaySection {
    if (this.hasFinished) {
      return 'None';
    }

    return this.properties.InstructionsAcknowledged
      ? 'Questions'
      : 'Instructions';
  }

  getResultSubheader() {
    return `YOU GOT ${
      this.totalCorrect
    } OUT OF ${this.choices.length.toLocaleString()} CORRECT`;
  }

  getOverriddenCallToActionText(cardDescriptor: CardDescriptor) {
    if (!this.result && !this.cardResult) {
      return undefined;
    }

    if (cardDescriptor.hasCompetition && !this.hasCompetitionEntry) {
      return (
        cardDescriptor.properties.CompetitionButtonText ||
        CardDescriptorProperties.DefaultCompetitionButtonText
      );
    }
  }

  private hasSwipedOption(optionId: Guid) {
    return this.choices.some((x) => x.optionId.equals(optionId));
  }
}

export type SwiperGameplaySection = 'Instructions' | 'Questions' | 'None';
export type SwiperResultView = 'Competition' | 'Result' | 'Answers';

export class FrontSwiperGameplayChoice {
  id: Guid;
  gameplayId: Guid;
  optionId: Guid;
  isCorrect?: boolean;
  swipedRight: boolean;

  constructor(props?: Partial<FrontSwiperGameplayChoice>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.gameplayId = Guid.valueOrNew(props.gameplayId);
    this.optionId = Guid.valueOrNew(props.optionId);
    this.swipedRight = asBoolean(props.swipedRight);
    if (!isNil(props.isCorrect)) {
      this.isCorrect = asBoolean(props.isCorrect);
    }
  }
}

export class SwiperGameplayProperties {
  InstructionsAcknowledged?: boolean;
  HasSeenCompetition?: boolean;

  constructor(props?: Partial<SwiperGameplayProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.InstructionsAcknowledged = asBoolean(props.InstructionsAcknowledged);
    this.HasSeenCompetition = asBoolean(props.HasSeenCompetition);
  }
}
