import { Guid } from '@/common/models/Guid';

export class PredictiveOptionPoints {
  optionId: Guid;
  points: number = 0;
  percentage: number = 0;
  totalAnswers: number = 0;

  constructor(props?: Partial<PredictiveOptionPoints>) {
    props = props || {};
    Object.assign(this, props);
    this.optionId = Guid.valueOrUndefined(props.optionId);
  }
}
