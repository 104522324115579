import { mapArray } from '../utils/ArrayFunctions';
import { asBoolean } from '../utils/BooleanFunctions';
import { ICardIdentifier } from './CardIdentifier';
import { CardSubTypes } from './CardSubTypes';
import { CardTypes } from './CardTypes';
import { Guid } from './Guid';
import { ImageDataModel } from './ImageDataModel';
import { Tag } from './Tag';

class CardTemplateModel {
  thumbnailImageJson?: string;

  get thumbnailImage() {
    return ImageDataModel.fromJsonOrUrl(this.thumbnailImageJson);
  }

  id: Guid;

  title?: string;
  description?: string;
  descriptionEditorStateJson?: string;
  assets?: CardTemplateAsset[];
  videoUrl?: string;
  hideCover?: boolean;

  constructor(props?: Partial<CardTemplateModel>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.assets = mapArray(props.assets, (x) => new CardTemplateAsset(x));
    this.hideCover = asBoolean(props.hideCover);
  }
}

export class AdminCardTemplateModel
  extends CardTemplateModel
  implements ICardIdentifier
{
  id: Guid;
  type: CardTypes;
  subType?: CardSubTypes;
  tags?: Tag[];

  constructor(props?: Partial<AdminCardTemplateModel>) {
    props = props || {};
    super(props);
    this.id = Guid.valueOrNew(props.id);
    this.tags = mapArray(props.tags, (x) => new Tag(x));
  }

  toString() {
    return JSON.stringify(this);
  }
}

export class CardTemplateAsset {
  id: Guid;
  templateAssetImageJson?: string;
  subheader?: string;
  get templateAssetImage() {
    return ImageDataModel.fromJsonOrUrl(this.templateAssetImageJson);
  }
  caption?: string;

  constructor(partial: Partial<CardTemplateAsset>) {
    Object.assign(this, partial);
    this.id = Guid.valueOrNew(partial.id);
  }
}
