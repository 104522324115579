import { Guid } from '@/common/models/Guid';
import { ImageDataModel } from '@/common/models/ImageDataModel';

export class FrontMemoryTile {
  imageData: string;
  id: Guid;
  isFlipped: boolean;

  constructor(props?: Partial<FrontMemoryTile>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrEmpty(props.id);
  }

  get tileImage() {
    return ImageDataModel.fromJsonOrUrl(this.imageData);
  }

  get tileFrontPlaceholderImage() {
    return ImageDataModel.fromJsonOrUrl(
      '/assets/images/front-add-image-placeholder.png'
    );
  }
}
