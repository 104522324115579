import { ReactNode } from 'react';

import { AssetTypes } from '@/common/models/AssetTypes';
import { ImageDataModel } from '@/common/models/ImageDataModel';

export type CarouselData =
  | {
      type: 'image';
      image: ImageDataModel;
    }
  | {
      type: 'video';
      url: string;
    }
  | {
      type: 'custom';
      content: ReactNode;
    };

export const resolveCarouselData = (
  type: AssetTypes,
  rawData: string
): CarouselData => {
  switch (type) {
    case AssetTypes.Image:
      return { type: 'image', image: ImageDataModel.fromJsonOrUrl(rawData) };
    case AssetTypes.Video:
      return { type: 'video', url: rawData };
    default:
      throw `Failed to resolve ${type} carousel data`;
  }
};

interface ButtonRenderOptions {
  label: string;
  selectedIndex: number;
  totalItems: number;
  clickHandler: () => void;
}

export interface CarouselNextButtonRenderOptions extends ButtonRenderOptions {
  hasNext: boolean;
}

export interface CarouselPreviousButtonRenderOptions
  extends ButtonRenderOptions {
  hasPrevious: boolean;
}
