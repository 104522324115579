import { Guid } from '@/common/models/Guid';
import { utcDateOrNow } from '@/common/utils/DateFunctions';

import { LiveSurveyQuestionStatuses } from '../shared';

export class FrontLiveSurveyQuestion {
  reactionsTotal: number = 0;

  id: Guid;
  cardId: Guid;
  userName: string;
  isAnswered: boolean = false;
  isPinned: boolean = false;
  content: string;
  createdAt: Date;
  updatedAt: Date;
  status: LiveSurveyQuestionStatuses;

  constructor(props?: Partial<FrontLiveSurveyQuestion>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.cardId = Guid.valueOrNew(props.cardId);
    this.createdAt = utcDateOrNow(props.createdAt);
    this.updatedAt = utcDateOrNow(props.updatedAt);
  }

  setTotalReactions(value: number) {
    this.reactionsTotal = value;
  }
}
