import { Guid } from '@/common/models/Guid';
import { asNumber, guidOrEmpty } from '@/common/utils';

export class QuizQuestionOptionWeighting {
  resultId: Guid;
  points: number;

  constructor(partial: Partial<QuizQuestionOptionWeighting>) {
    partial = partial || {};
    Object.assign(this, partial);
    this.resultId = guidOrEmpty(partial.resultId);
    this.points = asNumber(partial.points, 0);
  }
}
